import React from 'react';
import { Controller, UseModalFormReturnType } from '@pankod/refine-react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SaveButton,
  TextField
} from '@pankod/refine-mui';
import { ATTACKIQ_ID } from '@attackiq/constants';
import { v4 as uuidv4 } from 'uuid';

const CreatePhaseModal: React.FC<UseModalFormReturnType> = ({
  saveButtonProps,
  modal: { visible, close, title },
  control,
  handleSubmit,
  refineCore: { onFinish },
  formState: { errors }
}) => {
  const onSubmit = handleSubmit(validData => {
    // TODO: These shouldnt be required
    onFinish({
      ...validData,
      company: ATTACKIQ_ID,
      tracker_id: uuidv4()
    });
  });

  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{
        sx: {
          minWidth: 500
        }
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box component="form" autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Controller
            rules={{ required: 'Phase name is required' }}
            control={control}
            name="name"
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value || ''}
                onChange={onChange}
                error={!!errors.name}
                helperText={errors.name?.message}
                margin="normal"
                label="Phase Name"
                fullWidth
                required
                autoFocus
              />
            )}
          />

          <Controller
            rules={{ required: 'Phase description is required' }}
            control={control}
            name="description"
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value || ''}
                onChange={onChange}
                placeholder="Phase Description"
                label="Phase Description"
                fullWidth
                required
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={onSubmit} />
      </DialogActions>
    </Dialog>
  );
};

export default CreatePhaseModal;
