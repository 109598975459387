import { useCreate } from '@pankod/refine-core';
import { ATTACKIQ_ID, TAGGED_ITEM_MAP } from '@attackiq/constants';
import { Box, Create, useAutocomplete } from '@pankod/refine-mui';
import { FormProvider, useForm, useFormContext, useWatch } from '@pankod/refine-react-hook-form';
import { isEqual } from 'lodash';

import AiAutocompleteInput from '../../components/inputs/AutocompleteInput/AutocompleteInput';
import { Scenario, Phase, Stage, Mitigation, VendorProduct, VendorProductDomain } from '../../types';

const contentTypeOptions = Object.entries(TAGGED_ITEM_MAP).map(([key, value]) => ({
  model: key,
  displayName: value
}));

const TaggedItemsCreate = () => {
  const { saveButtonProps, ...formMethods } = useForm({
    refineCoreProps: {
      resource: 'tagged_items',
      action: 'create',
      redirect: false
    }
  });
  const { mutateAsync: createAsync } = useCreate();

  const { handleSubmit } = formMethods;

  const onSubmit = handleSubmit(async ({ content, content_type, tag }) => {
    content.forEach(async element => {
      await createAsync({
        resource: 'tagged_items',
        values: {
          tag: tag.id,
          object_id: element.id,
          content_type: content_type.displayName,
          company_id: ATTACKIQ_ID
        }
      });
    });
  });

  return (
    <FormProvider {...formMethods}>
      <form name="create-module-content-form" onSubmit={onSubmit}>
        <Create saveButtonProps={{ type: 'submit' }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <TagInput />
            <ContentTypeInput />
            <ContentInput />
          </Box>
        </Create>
      </form>
    </FormProvider>
  );
};

const TagInput = () => {
  const { autocompleteProps } = useAutocomplete<any>({
    resource: 'tags',
    fetchSize: 10000,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'name__icontains',
        operator: 'contains',
        value: searchText
      }
    ]
  });

  return (
    <AiAutocompleteInput
      {...autocompleteProps}
      sx={{ mt: 0.5 }}
      name="tag"
      rules={{ required: 'Tag is required' }}
      defaultValue={null}
      getOptionLabel={option => option.display_name || ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      TextFieldProps={{
        label: 'Tag'
      }}
    />
  );
};

const ContentTypeInput = () => {
  const contentType = useWatch({ name: 'content_type', defaultValue: null });
  const { setValue } = useFormContext();

  return (
    <AiAutocompleteInput
      name="content_type"
      defaultValue={null}
      options={contentTypeOptions}
      onChange={(_event, value) => {
        if (!isEqual(value, contentType)) {
          setValue('content', []);
        }
      }}
      getOptionLabel={option => {
        if (typeof option === 'string') return option;

        return option.displayName || '';
      }}
      isOptionEqualToValue={(option, value) => option.model === value.model}
      TextFieldProps={{
        label: 'Content Type'
      }}
    />
  );
};

const ContentInput = () => {
  const contentType: (typeof contentTypeOptions)[number] = useWatch({ name: 'content_type', defaultValue: null });
  const { autocompleteProps: scenariosAutocompleteProps } = useAutocomplete<Scenario>({
    resource: 'scenarios',
    fetchSize: 20,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'name__icontains',
        operator: 'contains',
        value: searchText
      }
    ]
  });
  const { autocompleteProps: phasesAutocompleteProps } = useAutocomplete<Phase>({
    resource: 'phases',
    fetchSize: 20,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'name__icontains',
        operator: 'contains',
        value: searchText
      }
    ]
  });
  const { autocompleteProps: mitigationsAutocompleteProps } = useAutocomplete<Mitigation>({
    resource: 'mitigations',
    fetchSize: 20,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'name__icontains',
        operator: 'contains',
        value: searchText
      }
    ]
  });
  const { autocompleteProps: stagesAutocompleteProps } = useAutocomplete<Stage>({
    resource: 'stages',
    fetchSize: 20,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'name__icontains',
        operator: 'contains',
        value: searchText
      }
    ]
  });
  const { autocompleteProps: vendorProductsAutocompleteProps } = useAutocomplete<VendorProduct>({
    resource: 'vendor_products',
    fetchSize: 20,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'name__icontains',
        operator: 'contains',
        value: searchText
      }
    ]
  });
  const { autocompleteProps: vendorProductDomainsAutocompleteProps } = useAutocomplete<VendorProductDomain>({
    resource: 'vendor_product_domains',
    fetchSize: 20,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'name__icontains',
        operator: 'contains',
        value: searchText
      }
    ]
  });

  const getAutocompleteProps = () => {
    const autoCompletePropsMap = {
      Scenario: scenariosAutocompleteProps,
      Phase: phasesAutocompleteProps,
      Mitigation: mitigationsAutocompleteProps,
      Stage: stagesAutocompleteProps,
      VendorProduct: vendorProductsAutocompleteProps,
      VendorProductDomain: vendorProductDomainsAutocompleteProps
    };
    return autoCompletePropsMap[contentType?.model] || { options: [] };
  };

  return (
    <AiAutocompleteInput
      {...getAutocompleteProps()}
      name="content"
      defaultValue={[]}
      disabled={!contentType}
      getOptionLabel={option => option.name || option.template_name || ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      TextFieldProps={{ label: 'Content' }}
      multiple
    />
  );
};

export default TaggedItemsCreate;
