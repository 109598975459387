import {
  List,
  DataGrid,
  useDataGrid,
  GridColumns,
  EditButton,
  Stack,
  DeleteButton,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  TextField,
  Button,
  Tooltip,
  Typography
} from '@pankod/refine-mui';
import { Controller, useForm } from '@pankod/refine-react-hook-form';
import { useMemo } from 'react';
import { CrudFilters, getDefaultFilter } from '@pankod/refine-core';

const VendorList = () => {
  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        width: 200,
        flex: 2,
        renderCell: params => {
          return (
            <Tooltip title={params.row.name}>
              <Typography variant="body1" noWrap>
                {params.row.name}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'logo',
        headerName: 'Logo',
        width: 200,
        flex: 2,
        renderCell: ({ value, row }: any) => {
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '32px',
                height: '32px'
              }}
              component="picture"
            >
              <img
                style={{
                  width: '100%',
                  height: '100%'
                }}
                src={value}
                alt={`${row.name} logo`}
              />
            </Box>
          );
        }
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 250,
        sortable: false,
        renderCell: params => {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        }
      }
    ],
    []
  );
  const { dataGridProps, filters, search } = useDataGrid({
    initialFilter: [
      {
        field: 'search',
        operator: 'eq',
        value: ''
      }
    ],
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { search } = params;

      filters.push({
        field: 'search',
        operator: 'eq',
        value: search
      });

      return filters;
    }
  });
  const { control, handleSubmit } = useForm({
    defaultValues: {
      search: getDefaultFilter('search', filters, 'eq') || ''
    }
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <Card>
          <CardHeader title="Filters" />
          <CardContent>
            <Box
              component="form"
              autoComplete="off"
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
              onSubmit={handleSubmit(search)}
            >
              <Controller
                name="search"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    label="Search"
                    placeholder="Search by name"
                    margin="normal"
                    fullWidth
                    autoFocus
                  />
                )}
              />

              <br />
              <Button type="submit" variant="contained">
                Apply
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={9}>
        <List canCreate>
          <DataGrid {...dataGridProps} filterModel={undefined} disableColumnFilter autoHeight columns={columns} />
        </List>
      </Grid>
    </Grid>
  );
};

export default VendorList;
