import { HttpError } from '@pankod/refine-core';
import Axios from 'axios';
import { capitalize } from 'lodash';

export const axiosInstance = Axios.create();

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const customError: HttpError = {
      ...error,
      message: buildErrorMessage(error.response.data),
      statusCode: error.response?.status
    };

    return Promise.reject(customError);
  }
);

function buildErrorMessage(data: string | object | Array<string | object>) {
  try {
    const message: string[] = [];

    if (typeof data === 'string') {
      message.push(data);
    }

    if (data instanceof Object) {
      Object.entries(data).forEach(([key, value]) => {
        const description: string = Array.isArray(value) ? value.join('\n') : value;

        switch (key) {
          case 'non_field_errors':
          case 'detail': {
            message.push(description);
            break;
          }
          case 'code': {
            message.push(`${capitalize(description.replace('_', ' '))}: `);
            break;
          }
          default:
            message.push(`${capitalize(key)}: ${description}`);
            break;
        }
      });
    }

    if (Array.isArray(data)) {
      data.forEach(item => {
        message.push(buildErrorMessage(item));
      });
    }

    return message.join('\n');
  } catch (error) {
    return 'An unknown error has occurred. Please contact support.';
  }
}

export default axiosInstance;
