import { ATTACKIQ_ID } from '@attackiq/constants';

function aiPcapInput($q, $parse, $interval, $timeout, FileFactory, $templateCache, Upload) {
  $templateCache.put(
    'pcap-input.html',
    [
      '<input accept=".pcap,.pcapng" type="file" id="pcap-input" style="display:none" class="pcap-input" ng-model="pcap" multiple/>',
      '<div>',
      '<div class="row">',
      '<div class="col-md-2">',
      '<button class="btn btn-block btn-default" ng-disabled="uploading || form.readonly || hasFile()" ng-click="selectFile()">Add File</button>',
      '</div>',
      '<div class="col-md-10">',
      '<div ng-repeat="file in selectedFiles()">',
      '<span class="btn btn-default file-input-file" style="position: relative; border-radius: 0px;">',
      '<span style="padding: 5px;">{{ fileName(file) }}</span>',
      '<a target="_blank" rel="noopener noreferrer nofollow popup=0" ng-href="/downloads/files/{{ file }}" tooltip="Download and View"><i class="fa fa-eye"></i></a>',
      '<a ng-if="!form.readonly" ng-click="replaceFile(file, $index)" tooltip="Replace"><i class="fa fa-pencil"></i></a>',
      '<a ng-if="!form.readonly" ng-click="removeFile(file, $index)" tooltip="Delete"><i class="fa fa-times"></i></a>',
      '</span>',
      '</div>',
      '<div ng-if="uploading">' +
        '<i class="fa fa-spinner fa-spin" style="font-size: 20px; color: #ff8a00;"></i> Uploading...' +
        '<a class="link padding-left" ng-click="cancel()">Cancel</a>' +
        '</div>',
      '</div>',
      '</div>',
      '<div ng-if="!hasFile()" class="help-block">max pcap file size 50MB</div>',
      '</div>'
    ].join('\n')
  );

  function link(scope, elem, attrs) {
    var uploadPromise = undefined;
    var getter = $parse(attrs.ngModel);
    var setter = getter.assign;
    var fileInput = angular.element(window.ASFShadowRoot.getElementById('pcap-input'));
    fileInput.toggleClass('pcap-input pcap-input-' + Math.ceil(Math.random() * 100000));
    var replaceMode = false;
    var fileToReplace;
    var pollingIntervalId = null;
    var timeoutId = null;
    var defaultParsingError = 'There has been an error while parsing the PCAP file provided';
    var parsingErrors = {
      1: defaultParsingError,
      2: 'The given PCAP file should contain a TCP converstaion bewteen two Assets'
    };

    scope.selectFile = selectFile;
    scope.removeFile = removeFile;
    scope.replaceFile = replaceFile;
    scope.selectedFiles = selectedFiles;
    scope.fileName = fileName;
    scope.cancel = cancelUpload;
    scope.hasFile = hasFile;

    angular.element(window.ASFShadowRoot).append(fileInput);
    function extractFileName(resp) {
      return (
        resp &&
        resp.file
          .split('?')[0]
          .split('/')
          .slice(-2)
          .join('/')
      );
    }

    function fileName(filePath) {
      return filePath && decodeURIComponent(filePath.split('/')[1]);
    }

    fileInput.bind('change', function(event) {
      var filesArray = [];
      scope.uploading = true;
      Array.prototype.push.apply(filesArray, event.target.files);
      event.preventDefault();
      event.stopPropagation();

      if (replaceMode) {
        canUpdate(fileToReplace)
          .then(function() {
            uploadPcap(filesArray[0]);
          })
          .catch(function() {
            scope.uploading = false;
            fileToReplace = undefined;
            replaceMode = false;
            window.alert(
              'You cannot replace a file owned by AttackIQ, please remove it from your scenario first and then add a new one.'
            );
          });
      } else {
        uploadPcap(filesArray[0]);
      }
    });

    function uploadPcap(pcapFile) {
      uploadPromise = Upload.upload({
        url: '/v1/files',
        file: pcapFile,
        headers: {
          'X-CSRFToken': window.csrfToken
        }
      });
      scope.$emit('$aiPcapUpload.uploadingFile');
      uploadPromise
        .then(function(data) {
          var file = data.data;
          if (
            !file.meta_data ||
            !file.meta_data.mime_type ||
            (file.meta_data.mime_type !== 'application/vnd.tcpdump.pcap' &&
              file.meta_data.mime_type !== 'application/x-pcapng')
          ) {
            window.alert('Please, you should provide a PCAP file');
            scope.uploading = false;
          } else {
            startPcapParserPolling(data.data.id);
          }
        })
        .catch(function(error) {
          stopPollingAndReset();
        });
    }

    function startPcapParserPolling(fileId) {
      scope.$emit('$aiPcapUpload.parsingPcapFile');
      pollingIntervalId = $interval(function() {
        getPcapParserStatus(fileId);
      }, 1000);
      timeoutId = $timeout(setErrorParsingPcap, 60000);
    }

    function stopPollingAndReset() {
      scope.uploading = false;
      clearPollingInterval();
    }

    function setErrorParsingPcap(errorCode) {
      stopPollingAndReset();
      scope.$emit('$aiPcapUpload.errorParsingPcapFile');
      var errorMessage = defaultParsingError;
      if (errorCode && parsingErrors[errorCode]) {
        errorMessage = parsingErrors[errorCode];
      }

      window.alert(errorMessage);
    }

    function clearPollingInterval() {
      $interval.cancel(pollingIntervalId);
      $timeout.cancel(timeoutId);
    }

    function getPcapParserStatus(fileId) {
      FileFactory.api()
        .query({ id: fileId })
        .$promise.then(function(file) {
          if (file.meta_data && file.meta_data.status && file.meta_data.status === 'DONE') {
            clearPollingInterval();
            scope.uploading = false;
            setPcapFile(file);
          } else if (file.meta_data && file.meta_data.status && file.meta_data.status === 'ERRORED') {
            setErrorParsingPcap(file.meta_data.error_code);
          }
        })
        .catch(function() {
          setErrorParsingPcap();
        });
    }

    function setPcapFile(file) {
      $timeout(function() {
        var modelValue = getter(scope);
        if (replaceMode) {
          deleteFile(true);
          fileToReplace = undefined;
          replaceMode = false;
          modelValue = getter(scope);
        }
        modelValue = extractFileName(file);
        setter(scope, modelValue);
        fileInput.val('');
        window.alert('Operation completed successfully.');
        scope.$emit('$aiPcapUpload.uploadCompleted', extractFileName(file));
        scope.$broadcast('schemaFormValidate'); // revalidate the form after the file has been uploaded
      });
    }

    function selectFile() {
      fileInput[0].click();
    }

    function canUpdate(file) {
      var d = $q.defer();
      var fileId = file.split('/')[0];

      FileFactory.api()
        .query({ id: fileId })
        .$promise.then(checkOwnership)
        .catch(d.reject);

      function checkOwnership(data) {
        if (data.company.id !== ATTACKIQ_ID) {
          d.resolve();
        } else {
          d.reject();
        }
      }

      return d.promise;
    }

    function replaceFile(file) {
      replaceMode = true;
      fileToReplace = file;
      fileInput[0].removeAttribute('multiple');
      fileInput[0].click();
      fileInput[0].setAttribute('multiple', 'multiple');
    }

    function removeFile(file) {
      const confirmed = window.confirm('Remove this file from the scenario?', 'Remove File?');

      if (confirmed) {
        deleteFile();
      }
    }

    function deleteFile(replace) {
      var fileRemoved = getter(scope);
      setter(scope, null);
      if (replace) {
        return;
      }
      scope.$emit('$aiPcapUpload.fileDeleted', fileRemoved);
    }

    function selectedFiles() {
      return getter(scope) && getter(scope).split(',');
    }

    function hasFile() {
      return selectedFiles() != null;
    }

    function cancelUpload() {
      if (uploadPromise) {
        fileInput.val('');
        uploadPromise.abort();
      }
      scope.$emit('$aiPcapUpload.pcapCanceled');
      stopPollingAndReset();
    }

    scope.$on('$aiPcapUpload.removeFiles', function() {
      selectedFiles().forEach(deleteFile);
    });

    scope.$on('$aiPcapUpload.cancelUpload', cancelUpload);
  }

  return {
    restrict: 'E',
    require: '^ngModel',
    templateUrl: 'pcap-input.html',
    link: link
  };
}

aiPcapInput.$inject = ['$q', '$parse', '$interval', '$timeout', 'FileFactory', '$templateCache', 'Upload'];
export default aiPcapInput;
