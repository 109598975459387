import {
  DataGrid,
  DateField,
  DeleteButton,
  EditButton,
  GridColumns,
  List,
  Stack,
  useDataGrid
} from '@pankod/refine-mui';
import { useMemo } from 'react';

const ReportTypeList = () => {
  const columns = useMemo<GridColumns>(
    () => [
      { field: 'name', headerName: 'Name', width: 200, flex: 2 },
      {
        field: 'created',
        headerName: 'Created At',
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        flex: 1
      },
      {
        field: 'modified',
        headerName: 'Updated At',
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        flex: 1
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 250,
        sortable: false,
        renderCell: params => {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        }
      }
    ],
    []
  );

  const { dataGridProps } = useDataGrid({
    columns
  });

  return (
    <List canCreate>
      <DataGrid {...dataGridProps} disableColumnFilter autoHeight />
    </List>
  );
};

export default ReportTypeList;
