import React from 'react';
import { useModal } from '@pankod/refine-core';
import ReactDiffViewer from '@mciccone/react-diff-viewer';
import { Box, Modal, ShowButton, Typography } from '@pankod/refine-mui';

interface Change {
  field: string;
  new: string | Object;
  old: string | Object;
}

interface Props {
  changes: Change[];
}

const ViewDiffButton: React.FC<Props> = ({ changes }) => {
  const { close, show, visible } = useModal();
  return (
    <>
      <ShowButton onClick={show} hideText />
      <Modal open={visible} onClose={close}>
        <Box
          sx={{
            overflow: 'auto',
            wordBreak: 'break-all',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            minHeight: '40%',
            maxHeight: '80%',
            bgcolor: 'background.paper',
            p: 2
          }}
        >
          <Typography variant="h4" gutterBottom component="h1">
            Changes
          </Typography>

          {changes.map(change => (
            <Box key={change.field}>
              <Typography variant="h6" component="h2">
                Field: {change.field}
              </Typography>
              <Box
                sx={{
                  p: 2
                }}
              >
                <ReactDiffViewer
                  oldValue={JSON.stringify(change.old)}
                  newValue={JSON.stringify(change.new)}
                  useDarkTheme
                  hideLineNumbers
                  extraLinesSurroundingDiff={2}
                  leftTitle="Previous value"
                  rightTitle="Modified value"
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Modal>
    </>
  );
};

export default ViewDiffButton;
