import { Backdrop, Button, Box, CircularProgress, Modal, Typography, IconButton } from '@pankod/refine-mui';
import { useCreate, useInvalidate, useModal, useResource, useUpdate } from '@pankod/refine-core';
import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import ScenarioDraggableItem from './ScenarioDraggableItem';
import { Autocomplete, TextField, useAutocomplete } from '@pankod/refine-mui';
import { useDrop } from 'react-dnd';
import useTestScenarios from 'apps/data/src/hooks/useTestScenarios';
import { ATTACKIQ_ID } from '@attackiq/constants';
import SavesOnTheFlyAlert from 'apps/data/src/components/SavesOnTheFlyAlert';
import { Close } from '@mui/icons-material';

interface IProps {
  testName: string;
  testId: string;
}

const ManageTestScenarios: React.FC<IProps> = ({ testName, testId }) => {
  const [_testScenarios, _setTestScenarios] = useState<any>([]);
  const [mutationInProgress, setMutationInProgress] = useState(false);

  const { visible, show, close } = useModal();
  const { id: project_template_id } = useResource();

  const { data: testScenarios, refetch } = useTestScenarios(testId);

  useEffect(() => {
    if (testScenarios) {
      _setTestScenarios(testScenarios);
    }
  }, [testScenarios]);

  const [, drop] = useDrop({
    accept: 'test_scenario'
  });

  const { mutateAsync: updateMutateAsync } = useUpdate();
  const { mutateAsync: createMutateAsync } = useCreate();
  const invalidate = useInvalidate();

  const mutateScenarioOrder = async () => {
    setMutationInProgress(true);
    for (const [index, testScenario] of _testScenarios.entries()) {
      await updateMutateAsync({
        resource: 'assessment_template_test_scenarios',
        id: testScenario.test_scenario_id,
        values: {
          order: index + 1
        },
        invalidates: []
      });
    }

    await refetch();
    setMutationInProgress(false);
  };

  const addNewScenario = async (scenarioId: string) => {
    setMutationInProgress(true);
    await createMutateAsync({
      resource: 'assessment_template_test_scenarios',
      values: {
        company: ATTACKIQ_ID,
        order: _testScenarios.length + 1,
        project_template: project_template_id,
        project_template_test: testId,
        scenario: scenarioId
      }
    });

    await refetch();
    setMutationInProgress(false);
  };

  const { autocompleteProps } = useAutocomplete<any>({
    resource: 'scenarios',
    fetchSize: 20,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'search',
        operator: 'eq',
        value: searchText
      }
    ]
  });

  const findTestScenario = (id: string) => {
    const testScenario = _testScenarios.find((testScenario: any) => testScenario.test_scenario_id === id);

    return {
      testScenario,
      index: _testScenarios.indexOf(testScenario as any)
    };
  };

  const moveTestScenario = (id: string, atIndex: number) => {
    const { testScenario, index } = findTestScenario(id);

    _setTestScenarios(
      update(_testScenarios, {
        $splice: [
          [index, 1],
          [atIndex, 0, testScenario]
        ]
      })
    );
  };

  const closeAndRevalidateTests = () => {
    invalidate({
      resource: 'assessment_template_tests',
      invalidates: ['all']
    });
    close();
  };

  return (
    <>
      <Button onClick={show}>Manage Scenarios</Button>
      <Modal open={visible} onClose={closeAndRevalidateTests}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            minHeight: '40%',
            maxHeight: '80%',
            bgcolor: 'background.paper',
            p: 3,
            overflow: 'auto'
          }}
        >
          <Backdrop open={mutationInProgress}>
            <CircularProgress />
          </Backdrop>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="h4" component="h1">
              Manage scenarios for "{testName}"
            </Typography>
            <IconButton onClick={closeAndRevalidateTests}>
              <Close />
            </IconButton>
          </Box>

          <Typography variant="overline">Add, remove or reorder scenarios</Typography>
          <SavesOnTheFlyAlert />

          {testScenarios?.length === 0 && (
            <Typography variant="body1" component="h1">
              No scenarios yet, add one below
            </Typography>
          )}

          <ol ref={drop}>
            {_testScenarios.map((testScenario: any) => (
              <ScenarioDraggableItem
                key={testScenario.test_scenario_id}
                id={testScenario.test_scenario_id}
                mutateTestScenario={mutateScenarioOrder}
                testId={testId}
                findTestScenario={findTestScenario}
                moveTestScenario={moveTestScenario}
                testScenario={testScenario}
              />
            ))}
          </ol>
          <Box>
            <Autocomplete
              {...autocompleteProps}
              getOptionLabel={(item: any) => item.name}
              filterOptions={options => {
                const alreadySelectedScenariosIds = _testScenarios.map((testScenario: any) => testScenario.scenario.id);
                return options.filter((option: any) => !alreadySelectedScenariosIds.includes(option.id));
              }}
              renderInput={params => (
                <TextField {...params} label="Search scenarios" margin="normal" variant="outlined" />
              )}
              onChange={(_, scenario) => {
                if (scenario) {
                  addNewScenario(scenario.id);
                }
              }}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default ManageTestScenarios;
