import { useRef } from 'react';
import { useAtomValue } from 'jotai';
import { Box, Button } from '@pankod/refine-mui';
import { dump } from 'js-yaml';

import { ReactAsf } from '@attackiq/react-asf';

import { schemaAtom, toggleAtom } from '../list';

const UIPreview = () => {
  const modelRef = useRef({});
  const toggledValue = useAtomValue(toggleAtom);
  const schema = useAtomValue(schemaAtom);
  const { form, config } = schema;

  const handleModelChange = newModel => {
    modelRef.current = newModel;
  };

  const handleCopyModel = () => {
    navigator.clipboard.writeText(dump(modelRef.current));
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ flex: '1 0 0', overflow: 'hidden' }}>
        <ReactAsf
          toggle={toggledValue}
          form={form}
          schema={config}
          model={{}}
          onModelChange={handleModelChange}
          fullHeight
        />
      </Box>

      <Button variant="contained" onClick={handleCopyModel}>
        Copy Model.YAML
      </Button>
    </Box>
  );
};

export default UIPreview;
