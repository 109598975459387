import React from 'react';
import { Box, DeleteButton, Typography } from '@pankod/refine-mui';
import { useDrag, useDrop } from 'react-dnd';
import { queryCache } from 'react-query';
interface Props {
  testScenario: any;
  id: string;
  testId: string;
  findTestScenario: any;
  moveTestScenario: any;
  mutateTestScenario: any;
}
const ScenarioDraggableItem: React.FC<Props> = ({
  testScenario,
  id,
  testId,
  findTestScenario,
  moveTestScenario,
  mutateTestScenario
}) => {
  const originalIndex = findTestScenario(id)?.index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'test',
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      }),
      end: (item, monitor) => {
        // Rolls back the test if it was dropped outside an acceptable slot
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();

        if (!didDrop) {
          moveTestScenario(droppedId, originalIndex);
          return;
        }

        // Hit the backend to update the test order
        mutateTestScenario();
      }
    }),
    [id, moveTestScenario, originalIndex]
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'test',
      hover: ({ id: draggedId }: { id: string; originalIndex: number }) => {
        if (draggedId !== id) {
          const { index: overIndex } = findTestScenario(id);
          moveTestScenario(draggedId, overIndex);
        }
      }
    }),
    [id, moveTestScenario, originalIndex]
  );

  const opacity = isDragging ? 0 : 1;

  return (
    <Box
      component="li"
      ref={(node: any) => drag(drop(node))}
      sx={{
        border: '1px dashed gray',
        padding: '0.5rem 1rem',
        marginBottom: '.5rem',
        backgroundColor: 'background.paper',
        cursor: 'move',
        opacity: opacity
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography>{testScenario.scenario.name}</Typography>
        <Box sx={{ display: 'flex' }}>
          <DeleteButton
            resourceNameOrRouteName="assessment_template_test_scenarios"
            recordItemId={testScenario.test_scenario_id}
            onSuccess={() => {
              queryCache.invalidateQueries(['testScenarios', testId]);
            }}
            hideText
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ScenarioDraggableItem;
