import { Box, Edit } from '@pankod/refine-mui';
import { FormProvider, useForm } from '@pankod/refine-react-hook-form';
import { useResource } from '@pankod/refine-core';

import { TextFieldInput } from '../../components';

const ModuleEdit = () => {
  const { id } = useResource();
  const {
    refineCore: { onFinish },
    saveButtonProps,
    ...formMethods
  } = useForm({
    refineCoreProps: { action: 'edit' },
    defaultValues: {
      name: '',
      display_name: '',
      description: ''
    }
  });

  const { handleSubmit } = formMethods;

  const onSubmit = handleSubmit(({ name, display_name, description }) => {
    onFinish({ name, display_name, description });
  });

  return (
    <FormProvider {...formMethods}>
      <form name="edit-module-form" onSubmit={onSubmit} autoComplete="off">
        <Edit recordItemId={id} saveButtonProps={{ type: 'submit' }} canDelete={false}>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextFieldInput name="name" label="Name" rules={{ required: 'Name is required' }} />
            <TextFieldInput name="display_name" label="Display Name" rules={{ required: 'Display name is required' }} />
            <TextFieldInput name="description" label="Description" />
          </Box>
        </Edit>
      </form>
    </FormProvider>
  );
};

export default ModuleEdit;
