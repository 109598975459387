import { IconButton, InputAdornment } from '@pankod/refine-mui';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';

import { useToggle } from '@attackiq/hooks';

import TextFieldInput, { TextFieldInputProps } from '../TextFieldInput/TextFieldInput';

export type PasswordTextFieldInputProps = TextFieldInputProps & {
  defaultVisibility?: boolean;
};

const PasswordTextFieldInput: React.FC<PasswordTextFieldInputProps> = ({ defaultVisibility, ...props }) => {
  const [showPassword, toggleShowPassword] = useToggle(defaultVisibility);

  return (
    <TextFieldInput
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label={showPassword ? 'hide password' : 'show password'} onClick={toggleShowPassword}>
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
};

PasswordTextFieldInput.defaultProps = {
  defaultVisibility: false
};

export default PasswordTextFieldInput;
