import { ATTACKIQ_ID } from '@attackiq/constants';

function aiFileInput($q, $parse, $timeout, $templateCache, FileFactory, Upload) {
  $templateCache.put(
    'file-input.html',
    [
      '<input type="file" id="file-input" style="display:none" multiple/>',
      '<div class="row">',
      '<div class="col-md-2">',
      '<button class="btn btn-block btn-default" ng-disabled="uploading || form.readonly" ng-click="selectFile()">Add File</button>',
      '</div>',
      '<div class="col-md-10">',
      '<div ng-repeat="file in selectedFiles()">',
      '<span class="btn btn-default file-input-file" style="position: relative; border-radius: 0px;">',
      '<span style="padding: 5px;">{{ fileName(file) }}</span>',
      '<a target="_blank" ng-href="/downloads/files/{{ file }}" tooltip="Download and View"><i class="fa fa-eye"></i></a>',
      '<a ng-if="!form.readonly" ng-click="replaceFile(file, $index)" tooltip="Replace"><i class="fa fa-pencil"></i></a>',
      '<a ng-if="!form.readonly" ng-click="removeFile(file, $index)" tooltip="Delete"><i class="fa fa-times"></i></a>',
      '</span>',
      '</div>',
      '<div ng-if="uploading">' +
        '<i class="fa fa-spinner fa-spin" style="font-size: 20px; color: #ff8a00;"></i> Uploading...' +
        '<a class="link padding-left" ng-click="cancel()">Cancel</a>' +
        '</div>',
      '</div>',
      '</div>'
    ].join('\n')
  );
  function link(scope, elem, attrs) {
    var uploadQueue = [];
    var getter = $parse(attrs.ngModel);
    var setter = getter.assign;
    var fileInput = angular.element(window.ASFShadowRoot.getElementById('file-input'));
    var replaceMode = false;
    var fileToReplace;

    scope.selectFile = selectFile;
    scope.removeFile = removeFile;
    scope.replaceFile = replaceFile;
    scope.selectedFiles = selectedFiles;
    scope.fileName = fileName;
    scope.cancel = cancelUpload;

    angular.element(window.ASFShadowRoot).append(fileInput);

    function extractFileName(resp) {
      return (
        resp &&
        resp.data.file
          .split('?')[0]
          .split('/')
          .slice(-2)
          .join('/')
      );
    }

    function fileName(filePath) {
      return filePath && decodeURIComponent(filePath.split('/')[1]);
    }

    function hasFiles(modelValue) {
      return modelValue && angular.isString(modelValue);
    }

    fileInput.bind('change', function(event) {
      var filesArray = [];
      scope.uploading = true;
      Array.prototype.push.apply(filesArray, event.target.files);

      if (replaceMode) {
        canUpdate(fileToReplace)
          .then(function() {
            uploadFiles(filesArray);
          })
          .catch(function() {
            scope.uploading = false;
            fileToReplace = undefined;
            replaceMode = false;
            window.alert(
              'You cannot replace a file owned by AttackIQ, please remove it from your scenario first and then add a new one.'
            );
          });
      } else {
        uploadFiles(filesArray);
      }

      fileInput[0].value = '';
      event.preventDefault();
    });

    function uploadFiles(filesArray) {
      uploadQueue = filesArray.map(function(fileObj) {
        if (replaceMode) {
          return Upload.upload({
            url: '/v1/files/' + fileToReplace.split('/').shift(),
            method: 'PATCH',
            file: fileObj,
            headers: {
              'X-CSRFToken': window.csrfToken
            }
          });
        } else {
          return Upload.upload({
            url: '/v1/files',
            file: fileObj,
            headers: {
              'X-CSRFToken': window.csrfToken
            }
          });
        }
      });

      $q.all(uploadQueue)
        .then(function(resultArray) {
          $timeout(function() {
            var modelValue = getter(scope);
            var files = resultArray.map(extractFileName);

            if (replaceMode) {
              deleteFile(fileToReplace, true);
              fileToReplace = undefined;
              replaceMode = false;
              modelValue = getter(scope);
            }

            modelValue = hasFiles(modelValue)
              ? modelValue
                  .split(',')
                  .concat(files)
                  .join(',')
              : files.join(',');
            setter(scope, modelValue);
            fileInput.val('');
            scope.$emit('$aiFileUpload.uploadCompleted', files);
          });
        })
        .finally(function() {
          uploadQueue = [];
          scope.uploading = false;
        });
    }

    function selectFile() {
      fileInput[0].click();
    }

    function canUpdate(file) {
      var d = $q.defer();
      var fileId = file.split('/')[0];

      FileFactory.api()
        .query({ id: fileId })
        .$promise.then(checkOwnership)
        .catch(d.reject);

      function checkOwnership(data) {
        if (data.company.id !== ATTACKIQ_ID) {
          d.resolve();
        } else {
          d.reject();
        }
      }

      return d.promise;
    }

    function replaceFile(file) {
      replaceMode = true;
      fileToReplace = file;
      fileInput[0].removeAttribute('multiple');
      fileInput[0].click();
      fileInput[0].setAttribute('multiple', 'multiple');
    }

    function removeFile(file) {
      const confirmed = window.confirm('Remove this file from the scenario?', 'Remove File?');

      if (confirmed) {
        deleteFile(file);
      }
    }

    function deleteFile(fileName, replace) {
      var files = selectedFiles();
      var fileRemoved = files.splice(files.indexOf(fileName), 1);
      files = files.length === 0 ? null : files.join(',');
      setter(scope, files);
      if (replace) {
        return;
      }
      scope.$emit('$aiFileUpload.fileDeleted', fileRemoved);
    }

    function selectedFiles() {
      return getter(scope) && getter(scope).split(',');
    }

    function cancelUpload() {
      uploadQueue.forEach(function(upload) {
        upload.abort();
      });
    }

    scope.$on('$aiFileUpload.removeFiles', function() {
      selectedFiles().forEach(deleteFile);
    });

    scope.$on('$aiFileUpload.cancelUpload', cancelUpload);
  }

  return {
    restrict: 'E',
    require: '^ngModel',
    templateUrl: 'file-input.html',
    link: link
  };
}

aiFileInput.$inject = ['$q', '$parse', '$timeout', '$templateCache', 'FileFactory', 'Upload'];
export default aiFileInput;
