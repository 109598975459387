import { ATTACKIQ_ID } from '@attackiq/constants';
import { Autocomplete, Box, Chip, TextField } from '@pankod/refine-mui';
import { useCreate, useDelete, useList, useResource } from '@pankod/refine-core';
import { useState } from 'react';

const PhaseTags = () => {
  const { id: phaseId } = useResource();
  const [tagSearchValue, setTagSearchValue] = useState('');
  const { mutateAsync: createAsync } = useCreate();
  const { mutateAsync: deleteAsync } = useDelete();

  const { data: phaseTags, refetch: refetchPhaseTags } = useList({
    resource: 'phase_tags',
    config: {
      pagination: {
        pageSize: 10000
      },
      filters: [{ field: 'phase', operator: 'eq', value: phaseId }],
      sort: [{ field: 'created', order: 'asc' }]
    }
  });

  const { data: tags } = useList({
    resource: 'tags',
    config: {
      pagination: {
        pageSize: 10000
      }
    }
  });

  const addNewPhaseTag = async (phaseId: string, tagId: string) => {
    try {
      await createAsync({
        resource: 'phase_tags',
        values: {
          company: ATTACKIQ_ID,
          phase: phaseId,
          tag: tagId
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      refetchPhaseTags();
    }
  };

  const removePhaseTag = async (phaseTagId: string) => {
    try {
      await deleteAsync({
        resource: 'phase_tags',
        id: phaseTagId
      });
    } catch (e) {
      console.error(e);
    } finally {
      refetchPhaseTags();
    }
  };

  const tagOptions = tags?.data?.filter(tag => !phaseTags?.data.find(phaseTag => phaseTag['tag'] === tag.id)) || [];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1
        }}
      >
        {phaseTags?.data?.map((phaseTag: any) => (
          <Chip
            onDelete={() => removePhaseTag(phaseTag.id)}
            size="small"
            key={phaseTag.id}
            label={`${phaseTag.tag_set_name}: ${phaseTag.tag_display_name}`}
          />
        ))}
      </Box>
      <Autocomplete
        disablePortal
        options={tagOptions}
        getOptionLabel={(option: any) => `${option.tag_set_name}: ${option.display_name}`}
        value={null}
        inputValue={tagSearchValue}
        onInputChange={(_event, value) => setTagSearchValue(value)}
        renderInput={(params: any) => <TextField {...params} label="Add tag" />}
        onChange={(_event, newlySelectedTag) => {
          addNewPhaseTag(phaseId as string, newlySelectedTag?.id as string);
          setTagSearchValue('');
        }}
      />
    </Box>
  );
};

export default PhaseTags;
