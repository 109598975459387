import {
  DataGrid,
  DeleteButton,
  GridColumns,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  Link,
  List,
  useDataGrid
} from '@pankod/refine-mui';
import { CrudFilters, useMany, useNavigation } from '@pankod/refine-core';

import { MODULE_CONTENT_MAP } from '@attackiq/constants';

import { Module, ModuleContent } from 'apps/data/src/types';

const ModuleContentList = () => {
  const { dataGridProps, search } = useDataGrid<ModuleContent>({
    initialFilter: [
      {
        field: 'search',
        value: '',
        operator: 'eq'
      }
    ],
    onSearch: search => {
      const filters: CrudFilters = [];

      filters.push({
        field: 'search',
        value: search,
        operator: 'eq'
      });

      return filters;
    }
  });
  const modulesIds = dataGridProps.rows.map((item: ModuleContent) => item.module_id);
  const { data: modulesData, isLoading: isLoadingModules } = useMany<Module>({
    resource: 'modules',
    ids: [...new Set(modulesIds)],
    queryOptions: {
      enabled: modulesIds.length > 0
    }
  });
  const { show } = useNavigation();

  const columns: GridColumns<ModuleContent> = [
    {
      field: 'content_type',
      headerName: 'Model',
      renderCell: params => MODULE_CONTENT_MAP[params.row.content_type_model],
      flex: 1
    },
    {
      field: 'content_object__name',
      headerName: 'Name',
      renderCell: params => params.row.content_object?.name || params.row.content_object?.template_name,
      sortable: false,
      flex: 2
    },
    {
      field: 'content_object__description',
      headerName: 'Description',
      renderCell: params => params.row.content_object?.description || params.row.content_object?.template_description,
      sortable: false,
      flex: 2
    },
    {
      field: 'module__display_name',
      headerName: 'Module',
      renderCell: ({ row }) => {
        if (isLoadingModules) return 'Loading...';

        const module = modulesData?.data.find(item => item.id === row.module_id);

        const handleClick = () => {
          show('modules', row.module_id);
        };

        return (
          <Link sx={{ cursor: 'pointer' }} onClick={handleClick}>
            {module?.display_name}
          </Link>
        );
      },
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: params => <DeleteButton recordItemId={params.row.id} hideText />,
      sortable: false,
      maxWidth: 100
    }
  ];

  return (
    <List canCreate>
      <DataGrid
        {...dataGridProps}
        filterModel={undefined}
        columns={columns}
        components={{ Toolbar: CustomToolbar }}
        onFilterModelChange={({ quickFilterValues }) => {
          search(quickFilterValues?.join(' ') || '');
        }}
        autoHeight
      />
    </List>
  );
};

const CustomToolbar = () => {
  return (
    <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default ModuleContentList;
