import { Box, Edit, TextField } from '@pankod/refine-mui';
import { Controller, useForm } from '@pankod/refine-react-hook-form';

const CloudProvidersEdit = () => {
  const {
    refineCore: { formLoading },
    control,
    saveButtonProps,
    formState: { errors }
  } = useForm();

  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} component="form" autoComplete="off">
        <Controller
          rules={{ required: 'Cloud provider name is required' }}
          control={control}
          name="name"
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value || ''}
              onChange={onChange}
              required
              error={!!errors.name}
              helperText={errors.name?.message}
              label="Cloud Provider Name"
              autoFocus
              fullWidth
            />
          )}
        />
      </Box>
    </Edit>
  );
};

export default CloudProvidersEdit;
