import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Edit,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  NativeSelect,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@pankod/refine-mui';
import { useCreate, useModal, useNavigation, useNotification, useOne } from '@pankod/refine-core';
import { useForm, Controller } from '@pankod/refine-react-hook-form';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

import { ReactAsf } from '@attackiq/react-asf';
import { AiReactJsonSchemaForm, AiRemirror } from '@attackiq/components';

import httpClient from '../../utils/axios';
import { getIsReactJsonSchemaScenarios } from '../../utils/getIsReactJsonSchema';
import { Scenario, ScenarioType } from '../../types';
import { SetContentLastUpdatedDate } from '../../components/SetContentLastUpdatedDate';
import ContentModules from '../../components/ContentModules';
import JSONEditor from '../../components/inputs/JSONEditor/JSONEditor';
import TabPanel from '../../components/mui/TabPanel/TabPanel';
import HistoryDrawer from './components/HistoryDrawer';
import ScenarioMitigationsTab from './components/ScenarioMitigationsTab';
import ScenarioTags from './components/ScenarioTags';

enum ScenarioTabs {
  General,
  Model,
  Platforms,
  Mitigations,
  Modules
}

const SCENARIO_TABS_THAT_SAVE_ON_THE_FLY = [ScenarioTabs.Mitigations];

const ScenarioEdit = () => {
  const [tab, setTab] = useState<ScenarioTabs>(ScenarioTabs.General);
  const [isReactJsonSchema, setIsReactJsonSchema] = useState<Boolean>(false);
  const [cloningInProgress, setCloningInProgress] = useState<boolean>(false);
  const {
    refineCore: { queryResult, formLoading, id },
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    refineCoreProps: {
      queryOptions: {
        staleTime: 0,
        cacheTime: 0,
        structuralSharing: false
      }
    }
  });
  const scenarioTemplateId = queryResult?.data?.data.scenario_template;
  const { data: scenarioTemplateResponse, isLoading } = useOne({
    resource: 'scenario_templates',
    id: queryResult?.data?.data.scenario_template,
    queryOptions: {
      enabled: !!scenarioTemplateId
    }
  });

  const { editUrl, list } = useNavigation();

  const { open } = useNotification();

  const { mutateAsync: createMutateAsync } = useCreate();

  const currentTabSavesOnTheFly = SCENARIO_TABS_THAT_SAVE_ON_THE_FLY.includes(tab);

  const isInstanceOfScenarioTemplate = queryResult?.data?.data.id === scenarioTemplateResponse?.data?.scenario_instance;

  const drawer = useModal();

  const handleTabChange = (_event: React.SyntheticEvent, value: number) => {
    setTab(value);
  };

  const onSubmit = handleSubmit(async validData => {
    if (validData.indicator_pattern === '') {
      delete validData.indicator_pattern;
    }
    if (isInstanceOfScenarioTemplate) {
      delete validData.scenario_template;
      delete validData.model_json;
    }
    if (validData.descriptor_json) {
      if (Object.keys(validData.descriptor_json.schema_output).length === 0) {
        delete validData.descriptor_json;
      }
    }

    // TODO: Once the v2/scenarios/:id is removed and the v1 endpoint works as the v2 endpoint, we can remove this
    // onFinish would hit PATCH /v1/scenarios/:id. The controller for v1 is not persisting changes made to the model_json or indicattor_patterns
    // onFinish(validData);

    // We manually hit PATCH /v2/scenarios/id instead
    try {
      await httpClient.patch(`/v2/scenarios/${id}`, validData);
      list('scenarios');
    } catch (e) {
      open?.({
        type: 'error',
        message: 'Error saving scenario'
      });
      console.error(e);
    }
  });

  const handleCloneScenario = async () => {
    const scenario = queryResult?.data?.data as any;
    scenario.name = `${scenario.name} ${new Date().toISOString()}`;

    setCloningInProgress(true);
    try {
      const response = await createMutateAsync({
        resource: `scenarios/${id}/save_copy`,
        values: scenario,
        successNotification: {
          type: 'success',
          message: 'Scenario cloned successfully'
        }
      });

      const url = editUrl('scenarios', response.data.id as string);

      window.open(url, '_blank');
    } catch (e) {
      console.error(e);
    } finally {
      setCloningInProgress(false);
    }
  };

  useEffect(() => {
    setIsReactJsonSchema(getIsReactJsonSchemaScenarios(scenarioTemplateResponse?.data?.descriptor_json));
  }, [scenarioTemplateResponse?.data?.descriptor_json]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Paper variant="outlined">
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="subtitle2">Scenario ID: {queryResult?.data?.data.id}</Typography>
            <Typography variant="subtitle2">
              Scenario Template:{' '}
              <Link href={editUrl('scenario_templates', queryResult?.data?.data.scenario_template)}>
                {queryResult?.data?.data.scenario_template}
              </Link>
              <br />
            </Typography>
            <Link href={scenarioTemplateResponse?.data?.zip_file} target="_blank">
              Download Scenario
            </Link>
            <Box sx={{ marginTop: 1 }}>
              <Button
                type="button"
                variant="contained"
                onClick={() => {
                  drawer.show();
                }}
              >
                HISTORY
              </Button>
            </Box>
            <br />
          </Box>
          <Box>
            <Button variant="contained" disabled={cloningInProgress} onClick={() => handleCloneScenario()}>
              Clone this scenario
            </Button>
          </Box>
        </Box>
      </Paper>
      <Edit
        canDelete
        isLoading={formLoading}
        footerButtons={({ defaultButtons }) => (
          <>
            <SetContentLastUpdatedDate<Scenario> resourceNameKey="name" />
            {defaultButtons}
          </>
        )}
        saveButtonProps={{
          onClick: onSubmit,
          disabled: currentTabSavesOnTheFly
        }}
      >
        <Box
          component="form"
          onSubmit={onSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <Controller
            rules={{ required: 'Scenario name is required' }}
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value || ''}
                onChange={onChange}
                error={!!errors.name}
                helperText={errors.name?.message}
                placeholder="Scenario name"
                label="Scenario name"
                autoFocus
                fullWidth
              />
            )}
          />
        </Box>
        <Box
          sx={{
            paddingY: 2
          }}
        >
          <Paper square variant="outlined">
            <Tabs value={tab} onChange={handleTabChange} aria-label="Sections of the scenario">
              <Tab label="General" disabled={isLoading} />
              <Tab label="Model" disabled={isLoading} />
              <Tab label="Platforms" disabled={isLoading} />
              <Tab label="Mitigations" disabled={isLoading} />
              <Tab label="Modules" disabled={isLoading} />
            </Tabs>

            <TabPanel value={tab} index={ScenarioTabs.General}>
              <Typography variant="body1">Scenario description*</Typography>

              <Controller
                control={control}
                name="description"
                rules={{ required: 'Scenario description is required' }}
                render={({ field: { onChange, value } }) => {
                  if (value === undefined) {
                    return <>Loading...</>;
                  }
                  return <AiRemirror value={value || ''} onChange={onChange} />;
                }}
              />

              <Controller
                control={control}
                name="time_to_live"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    error={!!errors.time_to_live}
                    helperText={errors.time_to_live?.message}
                    label="Time to live (ms)"
                    fullWidth
                  />
                )}
              />

              <Controller
                control={control}
                name="indicator_pattern"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    error={!!errors.indicator_pattern}
                    helperText={errors.indicator_pattern?.message}
                    label="Indicator pattern"
                    fullWidth
                  />
                )}
              />

              <FormControlLabel
                label="Cancellable"
                control={
                  <Controller
                    name="cancellable"
                    control={control}
                    render={({ field }) => {
                      return <Checkbox onChange={e => field.onChange(e.target.checked)} checked={!!field.value} />;
                    }}
                  />
                }
              />

              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="scenario-type">
                  Scenario type
                </InputLabel>
                <NativeSelect
                  defaultValue={queryResult?.data?.data?.scenario_type}
                  inputProps={{
                    name: 'scenario_type',
                    id: 'scenario-type'
                  }}
                  {...register('scenario_type', { required: true })}
                >
                  <option value={ScenarioType.Attack}>Attack</option>
                  <option value={ScenarioType.Validation}>Validation</option>
                </NativeSelect>
              </FormControl>
            </TabPanel>

            <TabPanel value={tab} index={ScenarioTabs.Model}>
              <Controller
                control={control}
                name="parameters_description"
                defaultValue={queryResult?.data?.data.parameters_description}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    fullWidth
                    multiline
                    label="Parameters description"
                    minRows={4}
                    maxRows={5}
                  />
                )}
              />

              <Typography variant="h6">Parameters:</Typography>
              <Controller
                name="model_json"
                control={control}
                defaultValue={queryResult?.data?.data.model_json}
                render={({ field }) => {
                  const resource = scenarioTemplateResponse?.data?.descriptor_json?.resources?.[0];
                  const { form, schema } = resource;
                  const uiSchema = cloneDeep(get(resource, 'ui_schema', {}));

                  if (isInstanceOfScenarioTemplate) {
                    schema.readonly = true;
                  }
                  return isReactJsonSchema ? (
                    <AiReactJsonSchemaForm
                      defaultFormData={field.value}
                      onChange={field.onChange}
                      schema={schema}
                      uiSchema={uiSchema}
                      id="data-scenario-detail-form"
                    />
                  ) : (
                    <ReactAsf onModelChange={field.onChange} schema={schema} form={form} model={field.value} />
                  );
                }}
              />
            </TabPanel>

            <TabPanel value={tab} index={ScenarioTabs.Platforms}>
              <Typography variant="h6">Supported platforms:</Typography>
              <Controller
                name="supported_platforms"
                control={control}
                defaultValue={queryResult?.data?.data.supported_platforms}
                render={({ field: { value, onChange } }) => {
                  return <JSONEditor value={value} onChange={onChange} />;
                }}
              />
            </TabPanel>

            <TabPanel value={tab} index={ScenarioTabs.Mitigations}>
              <DndProvider backend={HTML5Backend}>
                <ScenarioMitigationsTab />
              </DndProvider>
            </TabPanel>

            <TabPanel value={tab} index={ScenarioTabs.Modules}>
              <ContentModules contentType="scenario" />
            </TabPanel>
          </Paper>
        </Box>
      </Edit>
      <Card elevation={1}>
        <CardHeader title={<Typography variant="h5">Edit Scenario Tags</Typography>} />
        <CardContent>
          <ScenarioTags />
        </CardContent>
      </Card>
      <HistoryDrawer drawer={drawer} selectedId={id as string} model="Scenario" />
    </Box>
  );
};

export default ScenarioEdit;
