import { Controller, useFieldArray, useForm } from '@pankod/refine-react-hook-form';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { ATTACKIQ_ID } from '@attackiq/constants';
import { v4 as uuidv4 } from 'uuid';
import VendorProductSelector from '../../components/inputs/VendorProductSelector/VendorProductSelector';
import { Box, Button, Create, IconButton, TextField, Typography } from '@pankod/refine-mui';

const MitigationsCreate = () => {
  const methods = useForm({
    refineCoreProps: {
      queryOptions: {
        select: data => {
          return {
            data: {
              ...data.data,
              references: data.data['references'] || [] // References is sometimes null but it should be an empty array when there are no references
            }
          };
        }
      }
    }
  });

  const {
    refineCore: { formLoading },
    register,
    control,
    saveButtonProps,
    formState: { errors }
  } = methods;

  const { fields: referenceFields, append, remove } = useFieldArray({
    control,
    name: 'references'
  });

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} component="form" autoComplete="off">
        <Controller
          rules={{ required: 'Mitigation name is required' }}
          name="name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value || ''}
              onChange={onChange}
              error={!!errors.name}
              helperText={errors.name?.message}
              label="Mitigation Name"
              autoFocus
              fullWidth
              required
            />
          )}
        />
        <Controller
          rules={{ required: 'Mitigation description is required' }}
          name="description"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value || ''}
              onChange={onChange}
              label="Mitigation description"
              fullWidth
              required
              error={!!errors.description}
              helperText={errors.description?.message}
              multiline
              minRows={3}
              maxRows={5}
            />
          )}
        />
        <Typography variant="h6" component="h1">
          References
        </Typography>
        {referenceFields.map((field, index) => (
          <Box
            sx={{
              display: 'flex',
              gap: 2
            }}
            key={field.id}
          >
            <Controller
              rules={{ required: 'Reference title is required' }}
              name={`references.${index}.title`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value || ''}
                  onChange={onChange}
                  error={!!errors.references?.[index]?.title}
                  helperText={errors.references?.[index]?.title?.message}
                  label="Reference title"
                  fullWidth
                  required
                />
              )}
            />

            <Controller
              rules={{ required: 'Reference URL is required' }}
              control={control}
              name={`references.${index}.url`}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value || ''}
                  onChange={onChange}
                  label="Reference URL"
                  fullWidth
                  required
                  error={!!errors.references?.[index]?.url}
                  helperText={errors.references?.[index]?.url?.message}
                />
              )}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <IconButton onClick={() => remove(index)} aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        <Button
          variant="outlined"
          onClick={() =>
            append({
              title: '',
              url: ''
            })
          }
        >
          Add new reference
        </Button>

        <input
          type="hidden"
          {...register('company', {
            value: ATTACKIQ_ID
          })}
        />

        <input
          type="hidden"
          {...register('tracker_id', {
            value: uuidv4()
          })}
        />

        <VendorProductSelector control={control} name="vendor_product_id" />
      </Box>
    </Create>
  );
};

export default MitigationsCreate;
