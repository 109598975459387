import { differenceBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useList, useResource, useUpdate } from '@pankod/refine-core';
import update from 'immutability-helper';
import { useDrop, useDrag } from 'react-dnd';
import { ScenarioMitigation } from 'apps/data/src/types';
import { Box, Typography, Autocomplete, TextField, DeleteButton } from '@pankod/refine-mui';
import SavesOnTheFlyAlert from 'apps/data/src/components/SavesOnTheFlyAlert';

interface MitigationProps {
  id: string;
  name: string;
  description: string;
  moveMitigation: (id: string, to: number, mutate?: boolean) => void;
  findMitigation: (id: string) => { index: number };
  removeMitigation: (id: string) => void;
}

const ScenarioMitigationsTab = () => {
  const { id: scenarioId } = useResource();
  const [selectedScenarioMitigations, setSelectedScenarioMitigations] = useState<ScenarioMitigation[]>([]);
  const [mitigationsAutocompleteInputValue, setMitigationAutocompleteInputValue] = useState<string>('');

  const { data: mitigations } = useList<ScenarioMitigation>({
    resource: 'mitigations',
    config: {
      pagination: {
        pageSize: 10000
      }
    }
  });

  const { data: fetchedScenarioMitigations, refetch: refetchScenarioMitigations } = useList<ScenarioMitigation>({
    resource: `scenarios/${scenarioId}/mitigations`,
    config: {
      pagination: {
        pageSize: 10000
      }
    }
  });

  useEffect(() => {
    if (fetchedScenarioMitigations) {
      setSelectedScenarioMitigations(fetchedScenarioMitigations.data);
    }
  }, [fetchedScenarioMitigations]);

  const { mutateAsync: updateMutateAsync } = useUpdate<ScenarioMitigation[]>();

  const saveScenarioMitigations = async (newScenarioMitigations: ScenarioMitigation[]) => {
    await updateMutateAsync({
      resource: `scenarios`,
      id: `${scenarioId}/mitigations`,
      values: {
        data: newScenarioMitigations.map((mitigation, idx) => ({ ...mitigation, order: idx }))
      },
      metaData: {
        method: 'PUT'
      }
    });

    refetchScenarioMitigations();
  };

  const scenarioMitigationsOptions = differenceBy(mitigations?.data || [], selectedScenarioMitigations, 'id');

  const [, drop] = useDrop({
    accept: 'mitigation'
  });

  const findMitigation = (id: string) => {
    const mitigation = selectedScenarioMitigations.find(mitigation => mitigation.id === id) as ScenarioMitigation;

    return {
      mitigation,
      index: selectedScenarioMitigations.indexOf(mitigation)
    };
  };

  const moveMitigation = (id: string, atIndex: number, mutate?: boolean) => {
    const { mitigation, index } = findMitigation(id);

    const newScenarioMitigations = update(selectedScenarioMitigations, {
      $splice: [
        [index, 1],
        [atIndex, 0, mitigation]
      ]
    });
    setSelectedScenarioMitigations(newScenarioMitigations);

    if (mutate) {
      saveScenarioMitigations(newScenarioMitigations);
    }
  };

  const removeMitigation = (id: string) => {
    const newSelectedScenarioMitigations = selectedScenarioMitigations.filter(mitigation => mitigation.id !== id);
    saveScenarioMitigations(newSelectedScenarioMitigations);
  };

  return (
    <>
      <SavesOnTheFlyAlert />
      <Autocomplete
        disablePortal
        id="mitigation-select"
        options={scenarioMitigationsOptions}
        fullWidth
        value={null}
        inputValue={mitigationsAutocompleteInputValue}
        onInputChange={(_event, value) => setMitigationAutocompleteInputValue(value)}
        getOptionLabel={(mitigation: ScenarioMitigation) => mitigation.name}
        renderInput={params => <TextField {...params} label="Search mitigations to add" />}
        onChange={(_event, newlySelectedMitigation) => {
          if (newlySelectedMitigation) {
            saveScenarioMitigations([
              ...selectedScenarioMitigations,
              {
                id: newlySelectedMitigation.id,
                name: newlySelectedMitigation.name,
                description: newlySelectedMitigation.description,
                scenario: scenarioId,
                order: selectedScenarioMitigations.length,
                vendor_product: newlySelectedMitigation.vendor_product
              } as ScenarioMitigation
            ]);
            setMitigationAutocompleteInputValue('');
          }
        }}
      />

      <ol ref={drop}>
        {selectedScenarioMitigations.map(mitigation => {
          return (
            <ScenarioMitigationItem
              key={mitigation.id}
              id={mitigation.id}
              name={mitigation.name}
              description={mitigation.description}
              findMitigation={findMitigation}
              removeMitigation={removeMitigation}
              moveMitigation={moveMitigation}
            />
          );
        })}
      </ol>
    </>
  );
};

const ScenarioMitigationItem: React.FC<MitigationProps> = ({
  name,
  description,
  findMitigation,
  moveMitigation,
  removeMitigation,
  id
}) => {
  const originalIndex = findMitigation(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'mitigation',
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const { index: overIndex } = findMitigation(droppedId);
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveMitigation(droppedId, originalIndex);
        } else {
          moveMitigation(droppedId, overIndex, true);
        }
      }
    }),
    [id, moveMitigation, originalIndex]
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'mitigation',
      hover: ({ id: draggedId }: { id: string; originalIndex: number }) => {
        if (draggedId !== id) {
          const { index: overIndex } = findMitigation(id);
          moveMitigation(draggedId, overIndex);
        }
      }
    }),
    [findMitigation, moveMitigation]
  );

  const opacity = isDragging ? 0 : 1;
  return (
    <Box
      component="li"
      ref={(node: any) => drag(drop(node))}
      sx={{
        border: '1px dashed gray',
        padding: '0.5rem 1rem',
        marginBottom: '.5rem',
        backgroundColor: 'background.paper',
        cursor: 'move',
        opacity
      }}
    >
      <details>
        <summary>
          <Typography variant="body1" component="span">
            {name}
          </Typography>
        </summary>
        <Typography variant="body2">{description}</Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <DeleteButton
            hideText
            onClick={() => {
              removeMitigation(id);
            }}
          />
        </Box>
      </details>
    </Box>
  );
};

export default ScenarioMitigationsTab;
