import React, { forwardRef, Ref, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { AjvError, FormProps } from '@rjsf/core';
import { MuiForm5 } from '@rjsf/material-ui';
import { JSONSchema7 } from 'json-schema';
import * as R from 'remeda';

import CheckboxWidget from './components/CheckboxWidget';
import ObjectFieldTemplate from './components/ObjectFieldTemplate';
import SectionField from './components/SectionField';

declare module 'react' {
  function forwardRef<TRef, TProps = Record<string, unknown>>(
    render: (props: TProps, ref: React.Ref<TRef>) => React.ReactElement | null
  ): (props: TProps & React.RefAttributes<TRef>) => React.ReactElement | null;
}

declare module '@rjsf/core' {
  interface FormProps<T> {
    ref?: Ref<T> | undefined;
  }
}

interface IAiReactJsonSchemaFormProps<TFormData> extends Omit<FormProps<TFormData>, 'onChange' | 'ref'> {
  id?: string;
  defaultFormData?: TFormData;
  setFormState?: SetFormState;
  disableOnChangeValidation?: boolean;
  onChange?: (formData: TFormData) => void;
  formDataModel?: TFormData;
  showFormSubmitButton?: boolean;
  onlyRequiredFields?: boolean;
  disableTitles?: boolean;
}

interface IFormRef<TFormData> {
  submit: () => void;
  validate: (
    formData: TFormData,
    schema: JSONSchema7
  ) => {
    errors: AjvError[];
  };
  state: {
    formData: TFormData;
    schema: JSONSchema7;
  };
}

export interface AiReactJsonSchemaFormRef {
  submit: () => void;
  validate: () => { errors: AjvError[] };
}

export type SetFormState = (formState: FormState) => void;

export type FormState = { isValid: boolean; isDirty: boolean };

const AiReactJsonSchemaFormComponent = <TFormData,>(
  props: IAiReactJsonSchemaFormProps<TFormData>,
  ref: Ref<AiReactJsonSchemaFormRef>
) => {
  const {
    id = 'ai-react-json-schema-form',
    onChange,
    onSubmit,
    setFormState,
    disableOnChangeValidation,
    defaultFormData = {},
    formDataModel,
    showFormSubmitButton = false,
    schema: _schema,
    onlyRequiredFields,
    disableTitles,
    ...restProps
  } = props;
  const formRef = useRef<IFormRef<TFormData>>();
  const [formData, setFormData] = useState<TFormData | Record<string, unknown>>(defaultFormData);
  const schema = useMemo(() => {
    const schema = R.clone(_schema);

    if (onlyRequiredFields) deleteNonRequiredProperties(schema);

    if (disableTitles) deleteTitles(schema);

    return schema;
  }, [_schema, onlyRequiredFields, disableTitles]);

  const handleChange: FormProps<TFormData>['onChange'] = ({ formData }) => {
    onChange?.(formData);
    setFormData(formData);

    // Serves to disable the onChange validation if we opt for onSubmit validation
    if (disableOnChangeValidation) return;

    // A bit tricky and expensive to get the form state but it is part of our current experience. Consider using onSubmit instead.
    if (formRef.current) {
      const { errors } = doValidate() || {};

      setFormState?.({ isValid: !errors?.length, isDirty: true });

      return;
    }

    // If the ref is not in place yet, the form is pristine / not dirty
    setFormState?.({ isValid: false, isDirty: false });
  };

  const doSubmit = () => {
    formRef.current?.submit();
  };

  const doValidate = () => {
    return formRef.current?.validate(formRef.current.state.formData, formRef.current.state.schema) ?? { errors: [] };
  };

  useImperativeHandle(ref, () => ({
    submit: doSubmit,
    validate: doValidate
  }));

  return (
    <MuiForm5
      id={id}
      formData={formDataModel || formData}
      onSubmit={onSubmit}
      onChange={handleChange}
      widgets={{ CheckboxWidget }}
      fields={{ $aiSection: SectionField }}
      ObjectFieldTemplate={ObjectFieldTemplate}
      showErrorList={false}
      schema={schema}
      noHtml5Validate
      {...restProps}
      ref={formRef}
    >
      {!showFormSubmitButton && <>{/* Use a fragment to avoid rendering the default submit button */}</>}
    </MuiForm5>
  );
};

const deleteNonRequiredProperties = ({ required = [], properties = {} }: JSONSchema7) => {
  Object.entries(properties).forEach(([key, property]) => {
    if (typeof property === 'boolean') return;

    if (key === 'firedrill') delete properties[key];

    if (property.type === 'object') {
      deleteNonRequiredProperties(property);
    } else {
      if (!required.includes(key)) {
        delete properties[key];
      }
    }
  });
};

const deleteTitles = (schema: JSONSchema7) => {
  Object.values(schema.properties || {}).forEach(property => {
    if (typeof property === 'boolean') return;

    if (property.type === 'object') {
      // Empty string will prevent it from rendering the title
      property.title = '';
    } else {
      deleteTitles(property);
    }
  });
};

export const AiReactJsonSchemaForm = forwardRef(AiReactJsonSchemaFormComponent);
