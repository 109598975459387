import React from 'react';
import { Alert } from '@pankod/refine-mui';

const SavesOnTheFlyAlert = () => {
  return (
    <Alert severity="info">
      NOTE: Changes you make on this page will be saved on the fly to ensure data consistency.
    </Alert>
  );
};

export default SavesOnTheFlyAlert;
