import { MODULE_CONTENT_MAP } from '@attackiq/constants';

export enum ScenarioType {
  Attack = 1,
  Validation = 2
}

export interface Scenario {
  id: string;
  tracker_id: string;
  second_id: string;
  name: string;
  description: string;
  scenario_template: string;
  model_json: Record<string, unknown>;
  descriptor_json: null;
  extras: null;
  scenario_type: number;
  runnable: boolean;
  supported_platforms: SupportedPlatforms;
  company: string;
  user: null;
  created: string;
  modified: string;
  user_type: string;
  time_to_live: number;
  tags: Tag[];
  cancellable: boolean;
  cloud_providers: unknown[];
  is_multi_asset: boolean;
  indicator_pattern: string;
  indicator_source: null;
  parameters_description: null;
  mitigations: unknown[];
  modules: string[];
  module_contents: string[];
  last_updated: string;
}

export interface SupportedPlatforms {
  osx: string;
  amazon: string;
  centos: string;
  debian: string;
  redhat: string;
  ubuntu: string;
  windows: string;
}

export interface ProjectTemplate {
  id: string;
  vendor_product_id: null | string;
  modules: string[];
  module_contents: string[];
  created: string;
  modified: string;
  syncd_on: string;
  last_updated: string;
  template_name: string;
  template_description: string;
  project_name: string;
  project_description: string;
  project_id: null;
  icon: string;
  default_schedule: null;
  meta_data: MetaData;
  company: string;
  project_template_type: string;
  user_type: string;
  report_types: string[];
  widgets: string[];
  attack_graph_templates: string[];
}

export interface MetaData {
  behavior: string;
  category: string;
  assessment_type: string;
}

export type BlueprintTemplate = any;

export type AttackGraphTemplate = any;

// We only support equality filters
export type AttackIQFilter = {
  field: string;
  value: string | number;
};

export type ScenarioMitigation = {
  scenario: string;
  name: string;
  description: string;
  id: string;
  order: number;
  vendor_product: string | null;
};

export type Tag = {
  id: string;
  display_name: string;
  name: string;
  description: string;
  tag_set_id: string;
  tag_set_name: string;
};

export type Module = {
  id: string;
  name: string;
  display_name: string;
  description: string | null;
};

export type ModuleContent = {
  id: string;
  module_id: string;
  content_id: string;
  content_object: {
    id: string;
    name?: string;
    description?: string;
    template_name?: string;
    template_description?: string;
  };
  content_type_model: keyof typeof MODULE_CONTENT_MAP;
  content_type: number;
  content_type_app_label: 'ai_cloud' | string;
};

export interface Phase {
  id: string;
}

export interface Mitigation {
  id: string;
}

export interface Stage {
  id: string;
}

export interface VendorProduct {
  id: string;
}

export interface VendorProductDomain {
  id: string;
}

export interface FlexPackage {
  id: string;
  name: string;
}
