import { useState } from 'react';
import { Edit, Paper, Tab, Tabs } from '@pankod/refine-mui';
import { useForm, FormProvider } from '@pankod/refine-react-hook-form';

import { ATTACKIQ_ID } from '@attackiq/constants';

import { ProjectTemplate } from '../../types';
import { SetContentLastUpdatedDate } from '../../components/SetContentLastUpdatedDate';
import ContentModules from '../../components/ContentModules';
import TabPanel from '../../components/mui/TabPanel/TabPanel';
import GeneralTab from './components/tabs/GeneralTab/GeneralTab';
import ReportsTab from './components/tabs/ReportsTab/ReportsTab';
import TestsTab from './components/tabs/TestsTab/TestsTab';

enum AssessmentTemplateTabs {
  General,
  Reports,
  Tests,
  Modules
}

const AssessmentTemplateEdit = () => {
  const [tab, setTab] = useState(AssessmentTemplateTabs.General);
  const methods = useForm({
    refineCoreProps: {
      queryOptions: {
        cacheTime: 0,
        staleTime: 0
      }
    }
  });
  const {
    refineCore: { formLoading, onFinish },
    handleSubmit
  } = methods;

  const handleTabChange = (_event: React.SyntheticEvent, value: AssessmentTemplateTabs) => {
    setTab(value);
  };

  const onSubmit = handleSubmit(validData => {
    // TODO:
    // Project name and Project description are what the assessments using this template will be called, there is no need for these to exist because we use the template name and description
    // But the serializer needs this at the moment.
    onFinish({
      ...validData,
      project_id: null, // This shouldnt be required
      project_name: validData.template_name,
      project_description: validData.template_description,
      company: ATTACKIQ_ID
    });
  });

  return (
    <Edit
      isLoading={formLoading}
      footerButtons={({ defaultButtons }) => (
        <>
          <SetContentLastUpdatedDate<ProjectTemplate> resourceNameKey="template_name" />
          {defaultButtons}
        </>
      )}
      saveButtonProps={{
        onClick: onSubmit
      }}
    >
      <FormProvider {...methods}>
        <Paper variant="outlined" square>
          <Tabs value={tab} onChange={handleTabChange} aria-label="assessment template tabs">
            <Tab label="General" />
            <Tab label="Reports" />
            <Tab label="Tests" />
            <Tab label="Modules" />
          </Tabs>

          <TabPanel value={tab} index={AssessmentTemplateTabs.General}>
            <GeneralTab />
          </TabPanel>

          <TabPanel value={tab} index={AssessmentTemplateTabs.Reports}>
            <ReportsTab />
          </TabPanel>

          <TabPanel value={tab} index={AssessmentTemplateTabs.Tests}>
            <TestsTab />
          </TabPanel>

          <TabPanel value={tab} index={AssessmentTemplateTabs.Modules}>
            <ContentModules contentType="projecttemplate" />
          </TabPanel>
        </Paper>
      </FormProvider>
    </Edit>
  );
};

export default AssessmentTemplateEdit;
