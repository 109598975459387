import { Box, Create } from '@pankod/refine-mui';
import { FormProvider, useForm } from '@pankod/refine-react-hook-form';

import { ATTACKIQ_ID } from '@attackiq/constants';

import { TextFieldInput } from '../../components';

const ModuleCreate = () => {
  const {
    refineCore: { onFinish },
    saveButtonProps,
    ...formMethods
  } = useForm({
    refineCoreProps: { action: 'create' },
    defaultValues: {
      name: '',
      display_name: '',
      description: '',
      company_id: ATTACKIQ_ID
    }
  });

  const { handleSubmit } = formMethods;

  const onSubmit = handleSubmit(({ name, display_name, description }) => {
    onFinish({ name, display_name, description, company_id: ATTACKIQ_ID });
  });

  return (
    <FormProvider {...formMethods}>
      <form name="create-module-form" onSubmit={onSubmit} autoComplete="off">
        <Create saveButtonProps={{ type: 'submit' }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextFieldInput name="name" label="Name" rules={{ required: 'Name is required' }} />
            <TextFieldInput name="display_name" label="Display Name" rules={{ required: 'Display name is required' }} />
            <TextFieldInput name="description" label="Description" rules={{ required: 'Description is required' }} />
          </Box>
        </Create>
      </form>
    </FormProvider>
  );
};

export default ModuleCreate;
