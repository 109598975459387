import { Controller, useForm } from '@pankod/refine-react-hook-form';
import { Box, Create, TextField, Typography } from '@pankod/refine-mui';
import VendorSelector from '../../components/inputs/VendorSelector/VendorSelector';
import { useRef, useState } from 'react';
import { useCreate, useNavigation } from '@pankod/refine-core';
import { ATTACKIQ_ID } from '@attackiq/constants';

const EXTERNAL_URL_REGEX = /^(http|https):\/\/[^ "]+$/;

const VendorProductCreate = () => {
  const [selectedLogo, setSelectedLogo] = useState<Blob | null>(null);
  const previewRef = useRef<HTMLImageElement | null>(null);

  const {
    refineCore: { formLoading },
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { mutateAsync: createMutateAsync } = useCreate();
  const { edit } = useNavigation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: Blob | null = (event.target.files?.[0] as Blob) ?? null;

    const logoObjectURL = URL.createObjectURL(file);
    setSelectedLogo(file);

    if (previewRef.current) {
      previewRef.current.src = logoObjectURL;
    }
  };

  const onSubmit = handleSubmit(async validData => {
    const formData = new FormData();

    for (const [validKey, validValue] of Object.entries(validData)) {
      formData.append(validKey, validValue);
    }

    formData.append('company', ATTACKIQ_ID);

    if (selectedLogo) {
      formData.append('logo', selectedLogo);
    }

    const { data: newlyCreatedVendorProduct } = await createMutateAsync({
      resource: 'vendor_products',
      values: formData,
      metaData: {
        axiosConfigOverride: {
          transformRequest: () => {
            return formData;
          }
        }
      }
    });

    if (newlyCreatedVendorProduct.id) {
      edit('vendor_products', newlyCreatedVendorProduct.id);
    }
  });

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        onClick: onSubmit
      }}
    >
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
        onSubmit={onSubmit}
        autoComplete="off"
      >
        <Controller
          name="name"
          control={control}
          rules={{ required: 'Vendor product name is required' }}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value || ''}
              onChange={onChange}
              error={!!errors.name}
              helperText={errors.name?.message}
              label="Name"
              required
              fullWidth
            />
          )}
        />

        <VendorSelector
          control={control}
          name="vendor"
          rules={{ required: 'Vendor is required' }}
          inputProps={{
            error: !!errors.vendor,
            helperText: errors.vendor?.message
          }}
        />

        <Controller
          name="description"
          control={control}
          rules={{ required: 'Description is required' }}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value || ''}
              onChange={onChange}
              error={!!errors.description}
              helperText={errors.description?.message}
              label="Description"
              required
              fullWidth
              multiline
              rows={4}
            />
          )}
        />

        <Controller
          name="external_url"
          control={control}
          rules={{
            pattern: {
              value: EXTERNAL_URL_REGEX,
              message: 'External URL must be a valid URL'
            }
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value || ''}
              onChange={onChange}
              error={!!errors.external_url}
              helperText={errors.external_url?.message}
              label="External URL"
            />
          )}
        />

        <label htmlFor="logo-upload">
          <Typography variant="h6">Logo (Optional)</Typography>
        </label>

        <input type="file" id="logo-upload" onChange={handleChange}></input>

        <Box
          component="section"
          sx={{
            display: selectedLogo ? 'block' : 'none'
          }}
        >
          <Typography variant="h6">Preview</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '72px',
              height: '72px'
            }}
            component="picture"
          >
            <Box
              sx={{
                width: '100%',
                height: '100%'
              }}
              component="img"
              ref={previewRef}
            />
          </Box>
        </Box>
      </Box>
    </Create>
  );
};

export default VendorProductCreate;
