import { Button, Grid } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { AddButtonProps, ObjectFieldTemplateProps, utils } from '@rjsf/core';

const { canExpand } = utils;

// Should be in sync with https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/material-ui/src/ObjectFieldTemplate/ObjectFieldTemplate.tsx
const ObjectFieldTemplate = ({
  DescriptionField,
  description,
  TitleField,
  title,
  properties,
  required,
  disabled,
  readonly,
  uiSchema,
  idSchema,
  schema,
  formData,
  onAddClick
}: ObjectFieldTemplateProps) => {
  return (
    <>
      {(uiSchema['ui:title'] || title) && <TitleField id={`${idSchema.$id}-title`} title={title} required={required} />}
      {description && <DescriptionField id={`${idSchema.$id}-description`} description={description} />}
      <Grid container={true} spacing={2} alignItems={uiSchema['ui:fieldFlexAlign']}>
        {properties.map((element, index) => {
          // Remove the <Grid> if the inner element is hidden as the <Grid>
          // itself would otherwise still take up space.
          return element.hidden ? (
            element.content
          ) : (
            <Grid item={true} xs={getElementXs(element)} key={index} style={{ marginBottom: '10px' }}>
              {element.content}
            </Grid>
          );
        })}
        {canExpand(schema, uiSchema, formData) && (
          <Grid container justifyContent="flex-end">
            <Grid item={true}>
              <AddButton
                className="object-property-expand"
                onClick={onAddClick(schema)}
                disabled={disabled || readonly}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

// Should be in sync with https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/material-ui/src/AddButton/AddButton.tsx
const AddButton = (props: AddButtonProps) => {
  return (
    <Button {...props} color="secondary">
      <AddIcon /> Add Item
    </Button>
  );
};

const getElementXs = (element: ObjectFieldTemplateProps['properties'][number]) => {
  return element.content.props.uiSchema['ui:fieldFlexColumns'] || 12;
};

export default ObjectFieldTemplate;
