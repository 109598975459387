import { useMany } from '@pankod/refine-core';
import {
  List,
  DataGrid,
  useDataGrid,
  GridColumns,
  EditButton,
  Stack,
  DeleteButton,
  DateField,
  BooleanField,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  TextField,
  Button,
  Typography,
  Tooltip
} from '@pankod/refine-mui';
import { CrudFilters, getDefaultFilter } from '@pankod/refine-core';
import { Controller, useForm } from '@pankod/refine-react-hook-form';
import { useMemo } from 'react';

const VendorProductList = () => {
  const { dataGridProps, filters, search } = useDataGrid({
    initialFilter: [
      {
        field: 'search',
        operator: 'eq',
        value: ''
      }
    ],
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { search } = params;

      filters.push({
        field: 'search',
        operator: 'eq',
        value: search
      });

      return filters;
    }
  });

  const vendorIds = dataGridProps.rows.map(row => row.vendor);

  const { data: vendors, isLoading: vendorsIsLoading } = useMany({
    resource: 'vendors',
    ids: vendorIds,
    queryOptions: {
      enabled: vendorIds.length > 0
    }
  });

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell: params => {
          return (
            <Tooltip title={params.row.name}>
              <Typography variant="body1" noWrap>
                {params.row.name}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'vendor',
        headerName: 'Vendor',
        flex: 1,
        renderCell: ({ row }) => {
          if (vendorsIsLoading) {
            return 'Loading...';
          }
          const vendor = vendors?.data.find(item => item.id === row.vendor);
          return vendor?.name;
        }
      },
      {
        field: 'created',
        headerName: 'Created At',
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        flex: 1,
        sortable: false
      },
      {
        field: 'modified',
        headerName: 'Updated At',
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        flex: 1,
        sortable: false
      },
      {
        field: 'visible',
        headerName: 'Visible',
        renderCell: ({ value }) => <BooleanField value={value} valueLabelFalse="Not visible" valueLabelTrue="Visible" />
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 250,
        sortable: false,
        renderCell: params => {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        }
      }
    ],
    [vendors, vendorsIsLoading]
  );

  const { control, handleSubmit } = useForm({
    defaultValues: {
      search: getDefaultFilter('search', filters, 'eq') || ''
    }
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <Card>
          <CardHeader title="Filters" />
          <CardContent>
            <Box
              component="form"
              autoComplete="off"
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
              onSubmit={handleSubmit(search)}
            >
              <Controller
                name="search"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    label="Search"
                    placeholder="Search by name"
                    margin="normal"
                    fullWidth
                    autoFocus
                  />
                )}
              />

              <br />
              <Button type="submit" variant="contained">
                Apply
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={9}>
        <List canCreate>
          <DataGrid {...dataGridProps} filterModel={undefined} disableColumnFilter autoHeight columns={columns} />
        </List>
      </Grid>
    </Grid>
  );
};

export default VendorProductList;
