import {
  createTheme,
  DarkTheme as DefaultDarkTheme,
  LightTheme as DefaultLightTheme,
  responsiveFontSizes
} from '@pankod/refine-mui';

const LightTheme = createTheme({
  ...DefaultLightTheme,
  palette: {
    ...DefaultLightTheme.palette,
    divider: '#0000001f'
  }
});

const DarkTheme = createTheme({
  ...DefaultDarkTheme,
  palette: {
    ...DefaultDarkTheme.palette,
    divider: '#ffffff1f'
  }
});

const DarkThemeWithResponsiveFontSizes = responsiveFontSizes(DarkTheme);
const LightThemeWithResponsiveFontSizes = responsiveFontSizes(LightTheme);

export { LightThemeWithResponsiveFontSizes, DarkThemeWithResponsiveFontSizes };
