/**
 * This file was mostly take from react-admin data provider for django rest framework.
 * refine.dev did not have an official data provider for django rest framework to the date of this writing.
 */

import { stringify } from 'query-string';
import { DataProvider, LogicalFilter } from '@pankod/refine-core';
import type { AxiosInstance } from 'axios';

const getPaginationQuery = (pagination: any, isHistoryResource = false) => {
  if (isHistoryResource) {
    return { page: pagination.current, results_per_page: pagination.pageSize };
  }

  return {
    page: pagination.current,
    page_size: pagination.pageSize
  };
};

const getFilterQuery = (filters: LogicalFilter[] = []) => {
  return filters.reduce((acc: any, filter) => {
    if (filter.value !== undefined && filter.value !== null && filter.value !== '') {
      acc[filter.field] = filter.value;
    }
    return acc;
  }, {});
};

export const getOrderingQuery = (sort: any = []) => {
  if (sort.length === 0) {
    return {};
  }

  const [{ field, order }] = sort;
  return {
    ordering: `${order === 'asc' ? '' : '-'}${field}`
  };
};

const drfDataProvider = (apiUrl: string, httpClient: AxiosInstance): DataProvider => {
  const getOne: DataProvider['getOne'] = async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.get(url);

    return {
      data
    };
  };

  return {
    getList: async ({ resource, pagination, filters, sort }) => {
      // The history is paginated differently because it's managed by a middleware
      const isHistoryResource = resource === 'history';
      const query = {
        ...getFilterQuery(filters as LogicalFilter[]),
        ...getPaginationQuery(pagination || {}, isHistoryResource),
        ...getOrderingQuery(sort)
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      const { data } = await httpClient(url);

      if (isHistoryResource) {
        return {
          data: data.diffs.map((diff: any) => ({
            ...diff,
            id: diff.history_id
          })),
          total: data.num_items
        };
      }

      return {
        data: data.results,
        total: data.count
      };
    },

    getApiUrl: () => apiUrl,

    getOne,

    getMany: async ({ resource, ids }) => {
      const responses = await Promise.all(ids.map(id => getOne({ resource, id }))).then(data => ({
        data
      }));

      return {
        data: responses.data.flat().map(item => item.data)
      } as any;
    },

    update: async ({ resource, id, variables, metaData }) => {
      const preferedMethod = metaData?.method === 'PUT' ? httpClient.put : httpClient.patch;
      const axiosConfigOverride = metaData?.axiosConfigOverride;
      const { data } = await preferedMethod(`${apiUrl}/${resource}/${id}`, variables, axiosConfigOverride);
      return { data };
    },

    updateMany: ({ resource, ids }) =>
      Promise.all(ids.map(id => httpClient.patch(`${apiUrl}/${resource}/${id}`))).then(responses => ({
        data: responses.map(({ data }) => data.id)
      })),

    create: async ({ resource, variables, metaData }) => {
      const axiosConfigOverride = metaData?.axiosConfigOverride || {};
      const { data } = await httpClient.post(`${apiUrl}/${resource}`, JSON.stringify(variables), {
        headers: {
          'Content-Type': 'application/json'
        },
        ...axiosConfigOverride
      });

      return {
        data: { ...data }
      };
    },

    createMany: async ({ resource, variables }) => {
      const response = await Promise.all(
        variables.map(async param => {
          const { data } = await httpClient.post(`${apiUrl}/${resource}`, param, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          return data;
        })
      );

      return { data: response };
    },

    deleteOne: async ({ resource, id }) => {
      const { data } = await httpClient.delete(`${apiUrl}/${resource}/${id}`);

      return { data: data };
    },

    deleteMany: ({ resource, ids }) =>
      Promise.all(ids.map(id => httpClient.delete(`${apiUrl}/${resource}/${id}`))).then(() => ({
        data: []
      }))
  };
};

export default drfDataProvider;
