import {
  Box,
  CreateButton,
  DataGrid,
  DeleteButton,
  GridColumns,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  Paper,
  Show,
  Typography,
  useDataGrid
} from '@pankod/refine-mui';
import { CrudFilters, useNavigation, useResource } from '@pankod/refine-core';

import { MODULE_CONTENT_MAP } from '@attackiq/constants';

import { ModuleContent } from '../../types';
import useCurrentResource from '../../hooks/useCurrentResource';

const ModuleShow = () => {
  const { id } = useResource();
  const { data: module } = useCurrentResource();
  const { dataGridProps, search } = useDataGrid<ModuleContent>({
    resource: 'module_contents',
    permanentFilter: [
      {
        field: 'module_id',
        operator: 'eq',
        value: id
      }
    ],
    initialFilter: [
      {
        field: 'search',
        value: '',
        operator: 'eq'
      }
    ],
    onSearch: search => {
      const filters: CrudFilters = [];

      filters.push({
        field: 'search',
        value: search,
        operator: 'eq'
      });

      return filters;
    }
  });
  const { push, createUrl } = useNavigation();

  const columns: GridColumns<ModuleContent> = [
    {
      field: 'content_type',
      headerName: 'Model',
      renderCell: params => MODULE_CONTENT_MAP[params.row.content_type_model],
      flex: 1
    },
    {
      field: 'content_object__name',
      headerName: 'Name',
      renderCell: params => params.row.content_object?.name || params.row.content_object?.template_name,
      sortable: false,
      flex: 2
    },
    {
      field: 'content_object__description',
      headerName: 'Description',
      renderCell: params => params.row.content_object?.description || params.row.content_object?.template_description,
      sortable: false,
      flex: 2
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: params => (
        <DeleteButton resourceNameOrRouteName="module_contents" recordItemId={params.row.id} hideText />
      ),
      sortable: false,
      maxWidth: 100
    }
  ];

  const handleAddContent = () => {
    const moduleContentsCreateUrl = createUrl('module_contents');

    push(`${moduleContentsCreateUrl}?module_id=${id}`);
  };

  return (
    <>
      <Paper sx={{ mb: 2 }}>
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2">Module ID: {id}</Typography>
          <Typography variant="subtitle2">Module Name: {module?.display_name}</Typography>
        </Box>
      </Paper>
      <Show
        headerButtons={({ defaultButtons }) => {
          return (
            <>
              {defaultButtons}
              <CreateButton variant="text" onClick={handleAddContent}>
                Add Content
              </CreateButton>
            </>
          );
        }}
      >
        <Typography variant="h5" sx={{ pb: 2 }}>
          Content:
        </Typography>
        <DataGrid
          {...dataGridProps}
          filterModel={undefined}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          onFilterModelChange={({ quickFilterValues }) => {
            search(quickFilterValues?.join(' ') || '');
          }}
          disableColumnFilter
          autoHeight
        />
      </Show>
    </>
  );
};

const CustomToolbar = () => {
  return (
    <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default ModuleShow;
