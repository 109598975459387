import { ATTACKIQ_ID } from '@attackiq/constants';
import { useCreate, useDelete, useList, useResource } from '@pankod/refine-core';
import { Box, Checkbox, FormControlLabel } from '@pankod/refine-mui';
import SavesOnTheFlyAlert from 'apps/data/src/components/SavesOnTheFlyAlert';

const ReportsTab = () => {
  const { id: assessmentTemplateId } = useResource();

  const { data: selectedReportTypes, isLoading: selectedReportsIsLoading, refetch: refetchSelectedReports } = useList({
    resource: 'assessment_template_report_types',
    config: {
      pagination: {
        pageSize: 10000
      },
      filters: [
        {
          field: 'project_template_id',
          operator: 'eq',
          value: assessmentTemplateId
        }
      ]
    }
  });

  const { data: reportTypes, isLoading: reportTypesIsLoading, refetch: refetchReportTypes } = useList({
    resource: 'report_types',
    config: {
      pagination: {
        pageSize: 10000
      }
    }
  });

  const { mutateAsync: deleteMutateAsync } = useDelete();
  const { mutateAsync: createMutateAsync } = useCreate();

  const handleAddReportType = async (reportTypeId: string, assessmentId: string) => {
    await createMutateAsync({
      resource: 'assessment_template_report_types',
      values: {
        project_template: assessmentId,
        report_type: reportTypeId,
        company: ATTACKIQ_ID
      }
    });

    refetchReportTypes();
    refetchSelectedReports();
  };

  const handleRemoveReportType = async (assessmentReportTypeId: string) => {
    await deleteMutateAsync({
      resource: 'assessment_template_report_types',
      id: assessmentReportTypeId
    });

    refetchReportTypes();
    refetchSelectedReports();
  };

  if (reportTypesIsLoading || selectedReportsIsLoading) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: '60vw'
      }}
    >
      <SavesOnTheFlyAlert />
      {reportTypes?.data?.map(report => {
        const assessmentTemplateReportType = selectedReportTypes?.data?.find(
          asmtReportType => asmtReportType.report_type === report.id
        );
        const checked = !!assessmentTemplateReportType;
        return (
          <FormControlLabel
            label={report.name}
            key={report.id}
            sx={{
              display: 'block',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
            control={
              <Checkbox
                checked={checked}
                onChange={e => {
                  if (e.target.checked) {
                    handleAddReportType(report.id as string, assessmentTemplateId as string);
                  } else {
                    handleRemoveReportType(assessmentTemplateReportType?.id as string);
                  }
                }}
              />
            }
          />
        );
      })}
    </Box>
  );
};

export default ReportsTab;
