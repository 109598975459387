import {
  Button,
  CSSObject,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Theme,
  Tooltip,
  styled
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';

import { IF } from '../IF';

import { atom, useAtom } from 'jotai';

export const drawerOpenAtom = atom(true, (get, set, update: boolean | undefined) => {
  const prev = get(drawerOpenAtom);

  set(drawerOpenAtom, update ?? !prev);
});

export const useDrawerOpenAtom = () => useAtom(drawerOpenAtom);

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  boxShadow: theme.shadows[1],
  ...(open && {
    ...openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme)
  }),
  '& .MuiDrawer-paper': {
    paddingBottom: theme.spacing(2),
    position: 'static',
    border: 'none',
    ...(open && {
      ...openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme)
    })
  }
}));

const AiAppNavDrawer = ({ navRoutes }) => {
  const [isDrawerOpen, toggleDrawer] = useDrawerOpenAtom();

  return (
    <Drawer variant="permanent" open={isDrawerOpen}>
      <List sx={{ p: 1, flexGrow: 1 }}>
        {navRoutes.map(navRoute => {
          const { path, label, icon } = navRoute;
          return (
            <Tooltip key={path} title={!isDrawerOpen ? label : ''} disableInteractive>
              <ListItem sx={{ color: 'text.primary' }} disablePadding>
                <NavLink to={path} style={{ width: '100%', color: 'inherit' }}>
                  {({ isActive }) => (
                    <ListItemButton
                      selected={isActive}
                      sx={{ minHeight: 48, px: 2, justifyContent: isDrawerOpen ? 'initial' : 'center' }}
                    >
                      <ListItemIcon sx={{ minWidth: 0, mr: isDrawerOpen ? 4 : 'auto' }}>{icon}</ListItemIcon>
                      <IF condition={isDrawerOpen}>
                        <ListItemText primary={label} />
                      </IF>
                    </ListItemButton>
                  )}
                </NavLink>
              </ListItem>
            </Tooltip>
          );
        })}
      </List>

      <Button sx={{ color: 'text.primary' }} onClick={() => toggleDrawer()}>
        {isDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
      </Button>
    </Drawer>
  );
};

export default AiAppNavDrawer;
