import { BaseRecord, useOne, useResource } from '@pankod/refine-core';

const useCurrentResource = <TData extends BaseRecord>() => {
  const { id, resourceName, ...restResource } = useResource();
  const { data, ...rest } = useOne<TData>({ id: id as string, resource: resourceName });

  return { ...restResource, id, resourceName, data: data?.data || null, ...rest };
};

export default useCurrentResource;
