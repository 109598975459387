import { useController, UseControllerProps } from '@pankod/refine-react-hook-form';
import { TextField, TextFieldProps } from '@pankod/refine-mui';

export type TextFieldInputProps = UseControllerProps & TextFieldProps;

const TextFieldInput = ({
  name,
  control,
  rules,
  defaultValue,
  onChange,
  ...TextFieldProps
}: TextFieldInputProps) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  return (
    <TextField
      {...field}
      onChange={(...args) => {
        field.onChange(...args);
        onChange?.(...args);
      }}
      error={!!error}
      helperText={error?.message}
      variant="outlined"
      color="secondary"
      {...TextFieldProps}
    />
  );
};

export default TextFieldInput;
