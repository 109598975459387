import { ATTACKIQ_ID } from '@attackiq/constants';
import { Backdrop, Button, Box, CircularProgress, TextField, Typography } from '@pankod/refine-mui';
import { useCreate, useList, useResource, useUpdate } from '@pankod/refine-core';
import update from 'immutability-helper';
import React, { useState } from 'react';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TestDraggableItem from './components/TestDraggableItem';
import SavesOnTheFlyAlert from 'apps/data/src/components/SavesOnTheFlyAlert';

const TestsTab = () => {
  const [mutationInProgress, setMutationInProgress] = useState(false);
  const [newTestName, setNewTestName] = useState('');
  const [, drop] = useDrop({
    accept: 'test'
  });

  const [_tests, _setTests] = useState<any[]>([]);

  const { id: assessment_template_id } = useResource();
  const { refetch } = useList({
    resource: 'assessment_template_tests',
    config: {
      pagination: {
        pageSize: 10000
      },
      filters: [
        {
          field: 'project_template_id',
          operator: 'eq',
          value: assessment_template_id
        }
      ],
      sort: [
        {
          field: 'order',
          order: 'asc'
        }
      ]
    },
    queryOptions: {
      onSuccess: (data: any) => {
        // This state is managed locally to avoid sending mutations until the user is done dragging
        _setTests(data.data);
      }
    }
  });

  const { mutateAsync: updateMutateAsync } = useUpdate();
  const { mutateAsync: createMutateAsync } = useCreate();

  /**
   * Updates the order of the tests in the backend and revalidates the local state.
   */
  const mutateTestsOrder = async () => {
    setMutationInProgress(true);
    for (const [index, test] of _tests.entries()) {
      await updateMutateAsync({
        resource: 'assessment_template_tests',
        id: test.id,
        values: {
          order: index + 1
        },
        invalidates: []
      });
    }

    setMutationInProgress(false);
    await refetch();
  };

  const createNewTest = async () => {
    setMutationInProgress(true);
    try {
      await createMutateAsync({
        resource: 'assessment_template_tests',
        values: {
          name: newTestName,
          project_template: assessment_template_id,
          company: ATTACKIQ_ID,
          order: _tests.length + 1
        }
      });
    } catch {
      console.error('something went wrong');
    } finally {
      setMutationInProgress(false);
      setNewTestName('');
    }
  };

  const findTest = (id: string) => {
    const test = _tests.find(test => test.id === id) as any;

    return {
      test,
      index: _tests.indexOf(test)
    };
  };

  /**
   * Locally moves the test but doesn't trigger a mutation to the server
   */
  const moveTest = (id: string, atIndex: number) => {
    const { test, index } = findTest(id);

    _setTests(
      update(_tests, {
        $splice: [
          [index, 1],
          [atIndex, 0, test]
        ]
      })
    );
  };

  return (
    <>
      <SavesOnTheFlyAlert />
      <Backdrop open={mutationInProgress}>
        <CircularProgress />
      </Backdrop>
      <ol ref={drop}>
        {_tests.length === 0 && (
          <Typography variant="body1" component="h1">
            No tests yet, add one below
          </Typography>
        )}
        {_tests.map(({ name, description, id, scenarios }: any) => (
          <TestDraggableItem
            key={id}
            name={name}
            description={description}
            testScenarioIds={scenarios}
            findTest={findTest}
            mutateTests={mutateTestsOrder}
            moveTest={moveTest}
            id={id}
          />
        ))}
      </ol>
      {/* Add new test */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2
        }}
      >
        <TextField
          label="New test name"
          placeholder="New test name"
          value={newTestName}
          onChange={e => setNewTestName(e.target.value)}
          fullWidth
          onKeyPressCapture={e => {
            if (e.key === 'Enter') {
              createNewTest();
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        />
        <Button variant="outlined" color="primary" disabled={!newTestName} onClick={createNewTest}>
          Add
        </Button>
      </Box>
    </>
  );
};

const TestsTabWithDndProvider = (props: React.PropsWithoutRef<any>) => (
  <DndProvider backend={HTML5Backend}>
    <TestsTab {...props} />
  </DndProvider>
);

export default TestsTabWithDndProvider;
