import { Controller, useForm } from '@pankod/refine-react-hook-form';
import PhaseTags from './components/PhaseTags';
import PhaseMitigations from './components/PhaseMitigations';
import { Box, Card, Button, CardContent, CardHeader, Edit, Paper, TextField, Typography } from '@pankod/refine-mui';
import { useModal } from '@pankod/refine-core';
import HistoryDrawer from '../scenarios/components/HistoryDrawer';
import { AiRemirror } from '@attackiq/components';

const PhaseEdit = () => {
  const {
    refineCore: { formLoading, queryResult },
    control,
    formState: { errors },
    saveButtonProps
  } = useForm();

  const drawer = useModal();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Paper variant="outlined">
        <Box
          sx={{
            p: 2
          }}
        >
          <Typography variant="subtitle2">Phase ID: {queryResult?.data?.data['id']}</Typography>
          <Typography variant="subtitle2">Tracker ID: {queryResult?.data?.data['tracker_id']}</Typography>
        </Box>
        <Box sx={{ mx: 1.5 }}>
          <Button
            type="button"
            variant="contained"
            onClick={() => {
              drawer.show();
            }}
          >
            HISTORY
          </Button>
        </Box>
      </Paper>
      <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} component="form" autoComplete="off">
          <Controller
            rules={{ required: 'Phase name is required' }}
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value || ''}
                onChange={onChange}
                error={!!errors.name}
                helperText={errors.name?.message}
                required
                label="Phase Name"
                fullWidth
              />
            )}
          />

          <Controller
            rules={{ required: 'Phase description is required' }}
            name="description"
            control={control}
            render={({ field: { onChange, value } }) => {
              if (value === undefined) {
                return <>Loading...</>;
              }
              return <AiRemirror value={value || ''} onChange={onChange} />;
            }}
          />
        </Box>
      </Edit>
      <Card elevation={1}>
        <CardHeader title={<Typography variant="h5">Edit Phase Tags</Typography>}></CardHeader>
        <CardContent>
          <PhaseTags />
        </CardContent>
      </Card>
      <Card elevation={1}>
        <CardHeader title={<Typography variant="h5">Edit Phase Mitigations</Typography>}></CardHeader>
        <CardContent>
          <PhaseMitigations />
        </CardContent>
      </Card>

      <HistoryDrawer drawer={drawer} selectedId={queryResult?.data?.data['id'] as string} model="Phase" />
    </Box>
  );
};

export default PhaseEdit;
