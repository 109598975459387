import { useApiUrl } from '@pankod/refine-core';
import { useQuery } from 'react-query';
import httpClient from '../utils/axios';

// TODO: Refactor the backend to make this easier. This is awful

interface IWithScenarios {
  scenarios: string[];
}

/**
 * Given a testId will fetch scenarios information.
 */
const useTestScenarios = (testId: string) => {
  const apiUrl = useApiUrl();
  const query = useQuery(
    ['testScenarios', testId],
    async () => {
      // Retrieve an array of test_scenario_id
      const { data: test } = await httpClient.get<IWithScenarios>(`${apiUrl}/assessment_template_tests/${testId}`);

      // Find the scenarioIds from this other EP
      const testScenariosPromises = test.scenarios.map(scenarioTestId =>
        httpClient.get<any>(`${apiUrl}/assessment_template_test_scenarios/${scenarioTestId}`)
      );

      const testScenarios = (await Promise.all(testScenariosPromises)).map(({ data: scenarioTest }) => ({
        test_scenario_id: scenarioTest.id,
        scenarioId: scenarioTest.scenario,
        order: scenarioTest.order
      }));

      const scenariosPromises = testScenarios.map(testScenario =>
        httpClient.get(`${apiUrl}/scenarios/${testScenario.scenarioId}`)
      );

      const scenarios = (await Promise.all(scenariosPromises)).map(({ data }) => data);

      const scenariosWithTestScenarioInfo = testScenarios
        .map(testScenario => ({
          scenario: scenarios.find(scenario => scenario.id === testScenario.scenarioId),
          order: testScenario.order,
          test_scenario_id: testScenario.test_scenario_id
        }))
        .sort((a, b) => a.order - b.order);

      return scenariosWithTestScenarioInfo;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  return query;
};

export default useTestScenarios;
