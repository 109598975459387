import React from 'react';
import { AppBar, IconButton, Link, Stack, Toolbar } from '@pankod/refine-mui';
import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material';
import { useColorMode } from '../../contexts';

const Header: React.FC = () => {
  const { setMode, mode } = useColorMode();
  return (
    <AppBar color="default" position="sticky" elevation={1}>
      <Toolbar>
        <Stack direction="row" width="100%" justifyContent="space-between" gap={2} alignItems="center">
          <Stack direction="row" flex={1}></Stack>{' '}
          <Stack direction="row" alignItems="center">
            <IconButton
              onClick={() => {
                setMode();
              }}
            >
              {mode === 'dark' ? <LightModeOutlined /> : <DarkModeOutlined />}
            </IconButton>
          </Stack>
          <Stack direction="row" gap="4px" alignItems="center" justifyContent="center">
            <Link href="/logout">Logout</Link>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
