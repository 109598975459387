import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Typography, Box } from '@pankod/refine-mui';

interface DragAndDropProps {
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null | undefined>>;
  selectedFile: File | null | undefined;
  acceptType?: string | string[];
}

const DragAndDrop: React.FC<DragAndDropProps> = ({ setSelectedFile, selectedFile, acceptType }: DragAndDropProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: acceptType,
    onDrop: acceptedFiles => {
      acceptedFiles.forEach(file => {
        setSelectedFile(file);
      });
    }
  });

  return (
    <Box className="container">
      <Box sx={styles.dropzone}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <Typography variant="h6">Drag and drop or click here to select a file</Typography>
        </div>
      </Box>
      <Box sx={styles.selectedFile}>
        <Typography variant="h6">Selected file:</Typography>
        <Typography variant="body1" sx={styles.fileName}>
          {selectedFile ? selectedFile.path : '-'}
        </Typography>
      </Box>
    </Box>
  );
};

export default DragAndDrop;

const styles = {
  dropzone: {
    border: '1px dotted #ccc',
    borderRadius: 1,
    textAlign: 'center',
    cursor: 'pointer',
    p: 2,
    marginBottom: 1
  },
  selectedFile: {
    p: 1
  },
  fileName: {
    marginBottom: 1
  }
};
